import { Dispatch } from 'react';
import { combineReducers } from 'redux';
import { busyDispatcher, busyReducer, userSettingsReducer,errorReducer,applicationSettingsReducer,productSettingsReducer, messagesReducer,claimsDataReducer,userPreferencesReducer } from '.';
import {configurationReducer} from './states/ConfigurationState';
import { IAction } from '../../types';
import ServiceExtension from './ServiceExtension';
import { configureStore } from '@reduxjs/toolkit'

const onBusy = ( dispatchFn: Dispatch<IAction>, busy: boolean ) => busy
  ? busyDispatcher( dispatchFn ).setBusy()
  : busyDispatcher( dispatchFn ).setNotBusy();

export const serviceExtension = new ServiceExtension( onBusy );

const reducer = combineReducers( {
  busy: busyReducer,
  configuration: configurationReducer,
  userSettings: userSettingsReducer,
  error:errorReducer,
  applicationSettings:applicationSettingsReducer,
  productSettings:productSettingsReducer,
  messagesState: messagesReducer,
  claimsData:claimsDataReducer,
  userPreferences:userPreferencesReducer
} )

const appmiddleware = [
  store => dispatchFn => action => serviceExtension.dataService( store, dispatchFn, action )
]

export const AppStore = configureStore (
  {
    reducer,
    middleware:appmiddleware
  },
)
