import {
  MenuItem,
  Tooltip,
  IconButton,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  Box
} from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Combine, configurationDispatcher, configurationState, userSettingsDispatcher, userSettingsState,claimsDataState,userPreferencesState,userPreferencesDispatcher } from '../store';
import { getLanguageCode,getProductId,IsAppOpenedInIframe,IsAppOpenedByExtApp } from '../services';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import {ILanguages} from '../../types';
import { DefaultLanguage, ApplicationSupportedLanguages, PopperModifier, ESessionStore, EPageNames,EUrlParams } from '../data/Constants';
import { appSettings } from '../settings';
import { AppAuthContext } from '../services/Contexts';
import { SessionStore } from '../services/SessionStore';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';

function onPreferenceLangChange( {location,changeLanguage,setUserSettings,getSalesText,token,userPreferences,setUserPreferences} ) {
  if( userPreferences.preferenceChanged ) {
    const langCode = getLanguageCode( location.pathname,true );
    if( langCode ) {
      setUserSettings( {language: langCode} )
      changeLanguage( langCode );
    }
    setUserPreferences( {preferenceChanged: false} )    
    if( location.pathname !== EPageNames.LandingPage ) {
      if( appSettings.UseShortSalesText ) {
        getSalesText( langCode, token, getProductId(), 'short', false );
      }
      getSalesText( langCode, token, getProductId(), 'long', false );
    }
  } else {    
    setUserSettings( {language: userPreferences.language} );      
    changeLanguage( userPreferences.language );
  }
}

const onHandleLanguage = async ( value,handleLanguageProps ) => {
  const {resetModelSalesText,resetShortSalesText,availableLanguages,changeLanguage,handleClose,setUserSettings,token,getSalesText} = handleLanguageProps
  await resetModelSalesText();
  await resetShortSalesText();
  const lang = availableLanguages.find(
    ( _: { id: string } ) => _.code === value
  );
  lang && lang.code && changeLanguage( lang?.code );
  handleClose();
  setUserSettings( { language: lang?.code } );
  if( token && location.pathname !== EPageNames.LandingPage && location.pathname !== EPageNames.Logout ) {
    const bcpLangCode = lang?.code;
    const productModel = getProductId();
    if( appSettings.UseShortSalesText ) {
      await getSalesText( bcpLangCode, token, productModel, 'short', false );
    }
    if( IsAppOpenedInIframe() || IsAppOpenedByExtApp() ) {
      SessionStore.set( ESessionStore.Language,lang?.code.split( '-' )[0] );
      SessionStore.set( ESessionStore.Country,lang?.code.split( '-' )[1] );
    }
    await getSalesText( bcpLangCode, token, productModel, 'long', false );
  }
};

const handleLangCode = ( userSettings,changeLanguage )=>{
  // to convert the page into desired lang for LandingPage when click of home button
  if( location.pathname === EPageNames.LandingPage && IsDefaultFlowInSessionStore() ) {
    changeLanguage( userSettings.language )
  }
}

/**
 * Renders the ViewHandler dropdown where the user can change the view
 * @param {any} props the properties for the viewHandler
 * @returns {JSX.Element} the viewHandler Element
 */
export const $languageSetting = ( { userSettings,claimsData, configuration, getSalesText, resetModelSalesText, resetShortSalesText,setUserSettings,userPreferences,setUserPreferences }: any ) => {
  const token = useContext( AppAuthContext );
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const id = open ? 'scroll-playground' : undefined;
  const [arrowRef, setArrowRef] = React.useState( null );
  const languageRender = claimsData.featureFlags?.Language;  

  const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
    setAnchorEl( anchorEl ? null : event.currentTarget );
  };
  const handleClose = ( ) => {
    setAnchorEl( null )
  }
 
  useEffect( () => {
    const langCode = getLanguageCode( location.pathname );
    langCode && changeLanguage( langCode );
  }, [] );
  useEffect( () => {
    onPreferenceLangChange( {location,changeLanguage,setUserSettings,getSalesText,token,userPreferences,setUserPreferences } )
  }, [userPreferences.language] );
  useEffect( () => {
    handleLangCode( userSettings,changeLanguage );
  }, [JSON.stringify( configuration.pmLanguages ),location.pathname] );

  const changeLanguage = async ( lng: string ) => {
    try{
      i18n.changeLanguage( lng.replace( '-','_' ) );
    }catch( err ) {
      console.log( err )
    }
    
  };
  let availableLanguages: ILanguages[] = configuration?.pmLanguages 
    ? configuration?.pmLanguages 
    : DefaultLanguage.Value;
  if( location.pathname === EPageNames.LandingPage || location.pathname === EPageNames.Logout || location.pathname === EPageNames.CloseTab || location.pathname === EPageNames.Notified ) {
    availableLanguages = ApplicationSupportedLanguages
  }
  if( !languageRender?.active || !languageRender?.visible ) {
    return null
  }
  return <>
    <Tooltip title={ t( 'userPreference.language' ) }>
      <span>
        <IconButton disabled={ availableLanguages.length <= 1 || !IsDefaultFlowInSessionStore() || languageRender && !languageRender?.editable } className= { `languageContainer ${anchorEl ? 'activeIcon' : ''}` } onClick={ handleClick } aria-describedby={ id } data-testid="btn-language">
          <LanguageIcon fontSize="small" />
          {`${getLanguageCode( location.pathname )}`}
          <KeyboardArrowDownIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Popper className="zindex-100" id={ id } open={ open } anchorEl={ anchorEl } placement="bottom" role="tooltip" modifiers={ [
      ...PopperModifier,
      {
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowRef,
        }
      }
    ] }
    >
      <div role="tooltip">
        <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef }/>
        <Box >
          <Paper >
            <ClickAwayListener onClickAway={ handleClose }>
              <MenuList 
                autoFocusItem={ open }
                id="language-menu"
                aria-labelledby="language-button"
                data-testid="menu-language"
              >
                {availableLanguages.map( ( value: ILanguages ) => 
                  userSettings.language !== value.code &&
                  <MenuItem
                    onClick={ () => onHandleLanguage( value.code,{resetModelSalesText,resetShortSalesText,availableLanguages,changeLanguage,handleClose,setUserSettings,token,getSalesText} ) }
                    value={ value.name }
                    key={ value.code }
                    data-testid="userSettings-list"
                  >
                    {value.name}&nbsp;({value.code})
                  </MenuItem> 
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        
        </Box>
      </div>
    </Popper>
  </>
};

export const LanguageSetting = connect( Combine( configurationState, userSettingsState,claimsDataState,userPreferencesState ), Combine( configurationDispatcher, userSettingsDispatcher,userPreferencesDispatcher ) )( $languageSetting )