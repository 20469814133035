import { Box } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { errorDispatcher, busyState, Combine, configurationDispatcher, configurationState, userSettingsDispatcher, userSettingsState, applicationSettingsDispatcher, applicationSettingsState, messagesState, messagesDispatcher, claimsDataDispatcher, claimsDataState, userPreferencesDispatcher, userPreferencesState } from '../store';
import { ConflictDialog, SectionLayout,getLower, UnsupportedBrowser } from '.';
import { getSelectableSections, UrlHelper, onVariableChange, getLanguageCode, setOptional, checkFeatureSelected, checkIsMarkedOptional, isSubModelAssigned, getCurrencyCode, getProductId } from '../services';
import { IApplicationSettings, IConfigurationVariable, IConfiguratorWrapperProps, ICurrentSectionReturnType, IExtendedConfigureResponse, ILanguages, IValueType, ILastChange, IPriceListMap, IExtendedConfigureResponseOptional, IConfigureRequest, IClaimsData, INamed } from '../../types';
import { SessionStore } from '../services/SessionStore';
import { useTranslation } from 'react-i18next';
import { InfoDialog } from './InfoDialog';
import { EVariableType, ESessionStore, EChangeType, EUrlParams, EErrorTypes, DefaultLanguage, DefaultUserSettings, EMessagesType, ProductScope } from '../data/Constants'
import { countryCodes } from '../localization';
import { appSettings } from '../settings';
import WarningIcon from '@mui/icons-material/Warning';
import { AppAuthContext } from '../services/Contexts';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';
const changeOptionaItems = ( value: IValueType,variable: IConfigurationVariable,lastChange:ILastChange,request:IConfigureRequest,configuration:IExtendedConfigureResponse )=>{
  const vrblType = variable.variableType;
  let prop;
  if( lastChange.type === EChangeType.Remove ) {
    prop = value;
    
  }else if( lastChange.type === EChangeType.Change ) {
    if( ( vrblType === EVariableType.Number || vrblType === EVariableType.String ) && lastChange.value === '' ) {
      prop = variable.id;
      
    }else if( vrblType === EVariableType.Singleselect && lastChange.value !== lastChange.valueBefore ) {
      prop = lastChange.valueBefore;
      
    }
  }
  const flag = checkIsMarkedOptional( prop as string,variable );
  if( flag ) {
    setOptional( prop as string, false,variable );
    // adding optionalItems in request payload on uncheck or clear of feature
    addOptionalItemsToRequest( request,configuration )
  }
}

/**
 * To get the current section to display in configurator
 * @param {IExtendedConfigureResponse} configuration the configuration state
 * @param {IApplicationSettings} applicationSettings the application settings state
 * @param {(setApplicationSettings)} setApplicationSettings to update the application settings state
 * @returns {ICurrentSectionReturnType} the current section to display and view availability for the model
 */
export function getCurrentSection( configuration:IExtendedConfigureResponse,applicationSettings:IApplicationSettings ):ICurrentSectionReturnType {
  if ( !configuration.data?.sections ) {
    return {section:null,viewUnavailable:false};
  }
  const { activeTab } = applicationSettings;
  const {sections,viewUnavailable,searchCount,searchMatches } = getSelectableSections( configuration );
  if ( !sections.length ) {
    return {section:null,viewUnavailable};
  }
  // To check if all the sections in the current model are isItemsAvailable/hidden
  const hiddenAll = sections.filter( x => x.isItemsAvailable === true ) ;
  if( hiddenAll.length === sections.length ) {
    return { section:null, viewUnavailable, selectableItemsUnavailable:true,searchCount,searchMatches };
  }
  return {section:sections[activeTab],viewUnavailable,undefined,searchCount,searchMatches};
}

const configureApiCall = ( param )=>{
  const { request,configure,token,applicationSettings,setApplicationSettings,setLoaderMessage,setConfigureAPI,lastChange} = param;
  setLoaderMessage( 'configure' );
  configure( request, token, getLanguageCode(), lastChange ).then( res => {
    setLoaderMessage( null );
    setConfigureAPI( true );
    if ( res.error && !applicationSettings.isConfigurationChanged ) { // if error on 1st time configuration changed
      setApplicationSettings( { ...applicationSettings, isConfigurationChanged: false } );
    } else if( !res.error && !applicationSettings.isConfigurationChanged && res.configuration.removedAssignments.variableAssignments.length > 0 ) {
      //if not error & changing for 1st time but there are conflicts 
      setApplicationSettings( { ...applicationSettings, isConfigurationChanged: false } );
    }else { //if error but user is not changing for 1st time means there is change in configuration so enable button
      setApplicationSettings( { ...applicationSettings, isConfigurationChanged: true } );
    }
  } )
}

async function getPriceListMapData ( getPriceListMap: ( token: string ) => void,countryCode:string,token:string ) {
  const storedPriceListMap = SessionStore.get( ESessionStore.PriceListMap );
  let priceListMap = await ( storedPriceListMap ? storedPriceListMap : getPriceListMap( token ) );  
  if( !storedPriceListMap ) {
    SessionStore.set( ESessionStore.PriceListMap, priceListMap );
  }
  priceListMap = priceListMap?.find( ( priceMap:IPriceListMap )=> priceMap.country === countryCode )?.map;
  priceListMap = priceListMap ? priceListMap : countryCode;
  return priceListMap;
}

function getConfigurationId() {
  return UrlHelper.getSearchParameter( EUrlParams.ConfigurationId ) || SessionStore.get( ESessionStore.ConfigurationId ); 
}

function getUserMarket( configuration:IExtendedConfigureResponseOptional,applicationSettings:IApplicationSettings ) {
  const countryCode = configuration?.assignments?.find( res=>{
    return res.variableId === ProductScope.MarketId
  } )?.['value'];
  
  //returns true when the Country/Market given by User is loaded in applicationSettings
  return countryCode === applicationSettings.currency.countryCode ;
}

function getSubmodelPrice( configuration:IExtendedConfigureResponse,applicationSettings:IApplicationSettings,getPrice: ( token: string,countryCode:string,currencyCode:string,modelId:string ) => void,setModelPrice: ( id: string ) => void,token:string, modelPriceData:Map<string,boolean> ) {
  if( configuration.savedConfiguration && getUserMarket( configuration,applicationSettings ) ) {
    const countVariables = configuration?.contextData?.countVariables;
    const modelIds: string[] = [];
    const keys = Object.keys( countVariables );
    keys.forEach( variable => {
      const assignedValue = countVariables?.[variable]?.values?.find( v => v.state.isAssigned );
      if( assignedValue && Number( assignedValue.value ) > 0 ) {
        modelIds.push( variable );
      }
   
    } );
    if( modelIds.length > 0 && isValidCurrency( applicationSettings.currency.currencyCode ) ) {
      modelIds.forEach( mid=>{
        const id = mid?.split( '.' )?.pop()?.split( '_' )[0];
        if( SessionStore.get( ESessionStore.PriceListMap ) && !modelPriceData.get( id ) ) {
          getPrice( token,applicationSettings.priceListMap,applicationSettings.currency.currencyCode,id )
          setModelPrice( id )  
        }
      } )
    }
  }
}

function getLongSalesText( getSalesText,setModelSalesText,bcpLangCode ,token, modelId ) {
  getSalesText( bcpLangCode, token, modelId, 'long', false ).then( res => {
    setModelSalesText( modelId );
  } );
  if( bcpLangCode !== DefaultLanguage.Id ) {
    getSalesText( DefaultLanguage.Id, token, modelId, 'long', false ).then( res => {
      setModelSalesText( modelId );
    } );
  }
}

function getSubmodelMarketingInfo( param ) {
  const {configuration,getSalesText,setModelSalesText,getPrice,setModelPrice,token,applicationSettings,model,getProductImages} = param;
  const path = model?.split( '.' ) || [];
  const modelId = path.pop();
  if( model && configuration.data && isSubModelAssigned( configuration, path ) ) {
    const languageCode = getLanguageCode();
    const bcpLangCode = languageCode;    
    if( appSettings.UseShortSalesText && !configuration.shortSalesText['Model_' + modelId] ) {     
      getSalesText( bcpLangCode, token, modelId, 'short', false )
    }    
    if( isValidCurrency( applicationSettings.currency.currencyCode ) && !configuration.modelPriceData.get( modelId ) ) {
      getPrice( token,applicationSettings.priceListMap,applicationSettings.currency.currencyCode,modelId )
      setModelPrice( modelId )
    }
    if( !configuration.productImages['Model_' + modelId] ) {
      getProductImages( token,model );
    }
    if( !configuration.modelSalesTextData.get( modelId ) ) {
      getSalesText( bcpLangCode, token, modelId, 'long', false ).then( res => {
        setModelSalesText( modelId );
      } );
    }    
  }
}

function getClassSelector( applicationSettings:IApplicationSettings ) {
  return applicationSettings.pageSize.isExtraSmall ? 0 : 1
}

function addOptionalItemsToRequest( request:IConfigureRequest,configuration:IExtendedConfigureResponse ) {
  request.optionalItems = configuration.savedConfiguration?.optionalItems ? [ ...configuration.savedConfiguration.optionalItems.keys()] : []
}

function changeOptional( param ) {
  const {value,id,variable,configuration,token,onChange,setOptionalItems} = param;
  const configurationId = getConfigurationId() ;
  const vrblType = variable.variableType;
  const flag = value ? checkFeatureSelected( id,variable ) : true;// if checkbox checked 
  //check if feature is selected - not selected call onChange else call setOptionalItems api
  if( flag ) { //feature has value 
    setOptionalItems( token,configurationId,configuration.savedConfiguration?.optionalItems ? [...configuration.savedConfiguration.optionalItems.keys()] : [] )        
  }else{ // feature not selected
    switch ( vrblType ) {
      case EVariableType.Number:{
        const lower = getLower( variable.values );
        onChange( variable,lower,'optionalCheck' );
        break;
      }
      case EVariableType.String: onChange( variable,'-','optionalCheck' );
        break;
      default: onChange( variable,id.split( '.' ).pop(),'optionalCheck' ); // In case of singleselect & multiselect send selected feature id
    }
  }
}

function setPMLanguages( response: { pmLanguages: [{languages: ILanguages[] }]},userSettings,loading,setLanguageSelection,setUserPreferences ) {

  const userLanguage = response && response?.pmLanguages && response?.pmLanguages[0] && response?.pmLanguages[0]?.languages.find( lang => lang.code === userSettings.data.language );
  if( !loading && ( typeof userLanguage !== 'undefined' || response?.pmLanguages[0]?.languages.length === 1 ) ) {
    setLanguageSelection( true );
  }
  if( typeof userLanguage === 'undefined' ) {
    setLanguageSelection( true );
    setUserPreferences( {
      language: userSettings.data.language,
      showCode: userSettings.data.showCode
    } )
  }
}

async function getPMMarketingInfo( params ) {
  const {bcpLangCode ,token, productModel,getSalesText,getPrice,currencyCode,priceListMap,getProductImages} = params;
  if( isValidCurrency( currencyCode ) && SessionStore.get( ESessionStore.PriceListMap ) ) {
    await getPrice( token,priceListMap,currencyCode )
  }
  if( appSettings.UseShortSalesText ) {
    await getSalesText( bcpLangCode ,token, productModel, 'short', false ) //To fetch the salesText data based on the UseShortSalesText flag
  }   
  getProductImages( token,productModel );
  getSalesText( bcpLangCode ,token, productModel, 'long', false );
 
}

function setDefaultUserSettings( setUserPreferences ) {
  setUserPreferences( {...DefaultUserSettings,isUserSettingsDefault:true} );
  return {data:DefaultUserSettings}
}

const setUserSettingsAndLanguages = async ( langSettingProps ) => {
  const {languageSelection,loading,getUserPreferences,setUserPreferences, getPMLanguages, setLanguageSelection,token,configurationId} = langSettingProps
  if( !languageSelection && !loading ) {
    let userSettings;
    const lang = UrlHelper.getSearchParameter( EUrlParams.Language ) || SessionStore.get( ESessionStore.Language );
    const contry = UrlHelper.getSearchParameter( EUrlParams.Country ) || SessionStore.get( ESessionStore.Country )
    if( lang && contry ) {
      setUserPreferences( {
        language: lang + '-' + contry,
        showCode: true
      } );
      userSettings = {
        data : {
          language: lang + '-' + contry,
          showCode: true
        }
      }
    }else{
      userSettings = await getUserPreferences( token );
      if ( !userSettings ) {
        userSettings = setDefaultUserSettings( setUserPreferences )
      }
    }
    await getPMLanguages( configurationId, token )
      .then( ( response: { pmLanguages:[{languages: ILanguages[] }]} ) => {
        setPMLanguages( response,userSettings,loading,setLanguageSelection,setUserPreferences )
      } )
  }
}

const langCodeValue = ( languageCode,urlLanguageCode,setMessage,urlLanguage,t )=>{
  if( languageCode !== urlLanguageCode ) { //To set message when invalid language is passed
    setMessage( {
      text: urlLanguage ? t( 'errorMessages.invalidLanguage',{ urlLanguage:urlLanguageCode, fallbackLanguage:languageCode } ) : t( 'errorMessages.noLanguage',{ fallbackLanguage:languageCode } ),
      type: EMessagesType.Warning
    } );        
  }
}

const currencyCodeValue = ( isCurrencyValid:boolean,urlCurrency:string,setMessage,claimsData:IClaimsData,t )=>{
  if ( isCurrencyValid ) {
    return urlCurrency;
  } else if( !isFeatureVisible( claimsData.featureFlags.ListPrice ) && isFeatureVisible( claimsData.featureFlags.Currency ) ) {
    setMessage( {
      text: urlCurrency ? t( 'errorMessages.invalidCurrencyLPDisabled',{currency:urlCurrency} ) : t( 'errorMessages.noCurrency' ),
      type: EMessagesType.Warning
    } );
  } else if( isFeatureVisible( claimsData.featureFlags.ListPrice ) ) {
    setMessage( {
      text: urlCurrency ? t( 'errorMessages.invalidCurrency',{currency:urlCurrency} ) : t( 'errorMessages.noCurrency' ),
      type: EMessagesType.Warning
    } );
  }
  return 'NaN';
};

const popoverOnLoad = ( languageCode: string | INamed[],urlLanguageCode: string,isCurrencyValid: boolean,setOpenPopoverOnLoad: ( arg0: boolean ) => void )=>{
  if( languageCode !== urlLanguageCode || !isCurrencyValid ) {
    setOpenPopoverOnLoad( true );
  }
}
export const handleCurrencyCodeChange = ( isCurrencyValid:boolean, sessionCurrency: string , priceListMap: string, countryCurrency: { currency: string[]; } , setMessage , claimsData: IClaimsData, t ) =>{
  let currencyCode = '';
  if( sessionCurrency === null ) {//when no currency is passed from the url
    currencyCode = getCurrencyCode( priceListMap,countryCurrency );//gets the default currency for the country
    ( isFeatureVisible( claimsData.featureFlags.ListPrice ) || isFeatureVisible( claimsData.featureFlags.Currency ) ) && setMessage( {
      text: t( 'errorMessages.noCurrency',{fallbackCurrency:currencyCode} ),
      type: EMessagesType.Warning
    } );
  } else {
    //if sessionCurrency is invalid currencyCode will be NaN else it is sessionCurrency
    currencyCode = currencyCodeValue( isCurrencyValid,sessionCurrency,setMessage,claimsData,t );
  }
  return currencyCode;
}

const loadProduct = async ( params ) => {
  const { languageSelection, loading, getUserPreferences, token, setUserPreferences, getPMLanguages, setLanguageSelection, setLoading, loadConfiguration, i18n, navigate, setApplicationSettings, getPrice, getSalesText, getCurrencyList, getPriceListMap, getProductImages,getViewList, resetError, t, setMessage, setOpenPopoverOnLoad, claimsData } = params;
  //For PWA on edge, configurationId was not able to read it from url param hence reading it from session store. For chrome it will read from url param
  const configurationId = getConfigurationId() ;
  setUserSettingsAndLanguages( {languageSelection,loading,getUserPreferences,setUserPreferences, getPMLanguages, setLanguageSelection,token,configurationId} )
  setLoading( true );

  if( languageSelection ) {
    const languageCode = getLanguageCode();
    i18n.changeLanguage( languageCode.replace( '-','_' ) );
    const viewResponse = await getViewList( configurationId, token );
    if( viewResponse.error?.code === 400 || viewResponse.error?.code === 404 ) {
      resetError();
      navigate( '/Error' , {replace:true,state:{type: EErrorTypes.InvalidConfiguration,code:viewResponse.error?.code,message:viewResponse.error?.message}} ); 
      return;
    }
    const response = await loadConfiguration( configurationId, languageCode, token,UrlHelper.getSearchParameter( EUrlParams.ViewId ) || SessionStore.get( ESessionStore.ViewId ) );
    if ( response?.error || !configurationId ) {
      return;
    }
    const countryCode = response.data.userAssignments.find( ( res: { variableId: string; } )=>{
      return res.variableId === ProductScope.MarketId
    } )['value'];
    const priceListMap = await getPriceListMapData( getPriceListMap,countryCode,token )
    const sessionCurrency = SessionStore.get( ESessionStore.Currency );
    let currencyCode = '';
    //finds the country from PriceListMap and gets the currency list for it
    const countryCurrency = SessionStore.get( ESessionStore.PriceListMap ) ? await getCurrencyList( token,priceListMap ) : null;
    if( !IsDefaultFlowInSessionStore() ) { //To handle when currency is passed from down-stream application      
      const urlLanguage = SessionStore.get( ESessionStore.Language );
      const urlCountry = SessionStore.get( ESessionStore.Country );
      const urlLanguageCode = urlLanguage + '-' + urlCountry; // Concatenate the language and country to get the locale code
      langCodeValue( languageCode,urlLanguageCode,setMessage,urlLanguage,t );
      const isCurrencyValid = Object.values( countryCodes.codes ).map( x => x.currencyCode ).includes( sessionCurrency );
      currencyCode = handleCurrencyCodeChange( isCurrencyValid, sessionCurrency, priceListMap, countryCurrency, setMessage , claimsData, t ); 
      //displays messages for invalid language and currency
      popoverOnLoad( languageCode,urlLanguageCode,isCurrencyValid,setOpenPopoverOnLoad );
    } else {
      currencyCode = getCurrencyCode( priceListMap,countryCurrency );//To handle to get currency in case of default flow
    }
    const currency = {
      'countryCode': countryCode,
      'currencyCode':currencyCode,
      'locale':countryCodes.codes[countryCode]['locale']
    }
    setApplicationSettings( { currency: currency,priceListMap:priceListMap} );    
    const bcpLangCode = languageCode;
    const productModel = getProductId();
    getPMMarketingInfo( { bcpLangCode, token, productModel, getSalesText, getPrice, currencyCode, priceListMap, getProductImages } )
  }
}

function changeValue( params ) {
  const {request,requestType,configuration,configure,token,applicationSettings,setApplicationSettings,lastChange,value,variable,setLoaderMessage,setConfigureAPI} = params;
  if ( !request ) {
    return;
  }
  if( requestType !== 'optional' ) {
    changeOptionaItems( value,variable,lastChange,request,configuration );
  } 
  if( requestType === 'optionalCheck' ) {
    // adding optionalItems in request payload on check of Opt col when feature is not checked
    addOptionalItemsToRequest( request,configuration )
  }
  configureApiCall( {request,configure,token,applicationSettings,setApplicationSettings,setLoaderMessage,setConfigureAPI,lastChange } );
}

const handleTabClose = event => {
  event.preventDefault();  
  event.returnValue = '';
  return event.returnValue;
};

function addConfirmationListner( isChanged ) {
  if ( isChanged ) {
    window.addEventListener( 'beforeunload', handleTabClose, { capture: true } );
  } else {
    window.removeEventListener( 'beforeunload', handleTabClose, { capture: true } );
  }
}

function getPrimaryDetails( token,userSettings,getUserDetails,claimsData,getClaims ) {
  //to remove PriceListMap key from SessionStore if page is reloaded
  if ( performance.getEntriesByType( 'navigation' )[0].type === 'reload' ) {
    SessionStore.remove( ESessionStore.PriceListMap )
  }
  if( token && Object.keys( userSettings.details ).length === 0 ) {
    getUserDetails( token );
  }
  if( token && !claimsData.claims ) {
    getClaims( token ); 
  }
}

/**
 * Renders the currently selected Section and is responsible to handle the assignment functionality
 * @param {IConfiguratorProps} props the properties for the configurator component 
 * @returns {JSX.Element} the configurator component with the currently selected section
 */
export const $Configurator = ( props: IConfiguratorWrapperProps ) => {
  const { configuration, userSettings,claimsData, configure, getSalesText, loadConfiguration, busy, getUserPreferences, applicationSettings, setApplicationSettings, setUserPreferences, getPMLanguages, getPrice, getCurrencyList, setModelSalesText, setModelPrice, getPriceListMap, setLoaderMessage, setConfigureAPI, getProductImages,getUserDetails,getViewList, resetError, getClaims,setSearchValues,setOptionalItems, setMessage, setOpenPopoverOnLoad } = props;
  const token = useContext( AppAuthContext );
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState( false );
  const [languageSelection, setLanguageSelection] = useState( false );
  const navigate = useNavigate();
  const [serachParams] = useSearchParams();
  const model = serachParams.get( EUrlParams.Model ) || '';

  useEffect( () => {     
    addConfirmationListner( applicationSettings.isConfigurationChanged )
  }, [applicationSettings.isConfigurationChanged] );
  useEffect( () => {
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener( 'beforeunload', handleTabClose, { capture: true } );
    }
  }, [] )
  useEffect( () => {
    if ( !configuration.savedConfiguration && !loading || languageSelection || configuration.acceptedChanges ) {
      //load product directly if no product was loaded before or user aceepts the changes
      loadProduct( { languageSelection, loading, getUserPreferences, token, setUserPreferences, getPMLanguages, setLanguageSelection, setLoading, loadConfiguration, i18n, navigate, setApplicationSettings, getPrice, getSalesText, getCurrencyList, getPriceListMap, getProductImages, getViewList, resetError, t, setMessage, setOpenPopoverOnLoad, claimsData } );
    } 
  },[languageSelection] )

  useEffect( () => {
    getPrimaryDetails( token,userSettings,getUserDetails,claimsData,getClaims )
  }, [token] )

  useEffect( () => {
    //call price for submodel on page load
    getSubmodelPrice( configuration,applicationSettings,getPrice,setModelPrice,token,configuration.modelPriceData )
  },[configuration.data,applicationSettings.currency] )

  useEffect( () => {
    //call price and sales text for submodel on model change
    getSubmodelMarketingInfo( {configuration,getSalesText,setModelSalesText,getPrice,setModelPrice,token,applicationSettings,model,getProductImages} );  
  },[model,configuration.data] )

  const onChange = ( variable: IConfigurationVariable, value: IValueType,requestType?:string ) => {
    const lastChange = { type: '', variableId: variable.id, value, valueBefore: null };
    const request = onVariableChange( configuration, variable, value, lastChange );
    changeValue( {request,requestType,configuration,configure,token,applicationSettings,setApplicationSettings,lastChange,value,variable,setLoaderMessage,setConfigureAPI} )
  }
 
  const {section:currentSection,viewUnavailable,selectableItemsUnavailable,searchCount,searchMatches} = getCurrentSection( configuration,applicationSettings );
  useEffect( () => { 
    setSearchValues( searchCount || {searchCount:0},searchMatches || [] )
  }, [JSON.stringify( searchMatches ) ,searchCount?.searchCount] )
  // To display message when all the families in the section are hidden
  if( currentSection?.isItemsAvailable || selectableItemsUnavailable ) {
    return <Box className="root text-left align-center d-flex noSelectableMessage">
      <WarningIcon className="warningIcon"/> &nbsp; {t( 'configurator.noSelectableItems' )}
    </Box>;
  }

  if ( !busy && !currentSection && configuration.data ) {
    return <Box className="root text-left align-top justify-left">
      {viewUnavailable && !selectableItemsUnavailable ? t( 'configurator.viewUnavailable' ) : t( 'configurator.noSelectableSections' )}
    </Box>;
  }

  const onOptionalChange = ( id: string, value: boolean,variable:IConfigurationVariable ) => {
    setOptional( id, value,variable );
    changeOptional( {value,id,variable,configuration,token,onChange,setOptionalItems} )
  }

  const classSelector = getClassSelector( applicationSettings );
  const padding = ['p-10px','p-15px'];
  return <Box className={ `configurator-root root ${padding[classSelector]}` }>
    <Box className="configurator-content">
      {currentSection && <SectionLayout section={ currentSection } onChange={ onChange } onOptionalChange={ onOptionalChange } />}
    </Box>
    <ConflictDialog />
    <InfoDialog/>
    <UnsupportedBrowser/>
  </Box>;
}

/**
 * Checks for required props and renders the configurator
 * @param {IConfiguratorWrapperProps} props the properties for the configurator wrapper component
 * @returns {JSX.Element} the configurator component or null if required properties are missing
 */
const $ConfiguratorWrapper = ( props: IConfiguratorWrapperProps ) => {
  const { configuration, userSettings, configure, getSalesText, loadConfiguration, busy, token, getUserPreferences, applicationSettings, setApplicationSettings, setUserPreferences, getPMLanguages, getPrice, getCurrencyList, setModelSalesText, setModelPrice, getPriceListMap, setLoaderMessage, setConfigureAPI, getProductImages, getUserDetails, getViewList, resetError, getClaims, setSearchValues, setOptionalItems, setMessage, setOpenPopoverOnLoad,claimsData } = props;
  if ( !configuration || !loadConfiguration || !applicationSettings ) {
    return null;
  }

  return <$Configurator
    configuration={ configuration }
    userSettings = { userSettings }
    claimsData = { claimsData }
    configure={ configure }
    getSalesText = { getSalesText }
    loadConfiguration={ loadConfiguration }
    token={ token }
    busy = { busy }
    getUserPreferences={ getUserPreferences }
    applicationSettings={ applicationSettings }
    setApplicationSettings={ setApplicationSettings }
    setUserPreferences={ setUserPreferences }
    getPMLanguages={ getPMLanguages }
    getPrice={ getPrice }
    getCurrencyList={ getCurrencyList }
    setModelSalesText={ setModelSalesText }
    setModelPrice={ setModelPrice }
    getPriceListMap={ getPriceListMap }
    setLoaderMessage={ setLoaderMessage }
    setConfigureAPI={ setConfigureAPI }
    getProductImages = { getProductImages }
    getUserDetails = { getUserDetails }
    getViewList={ getViewList }
    resetError = { resetError }
    getClaims = { getClaims }
    setSearchValues = { setSearchValues }
    setOptionalItems = { setOptionalItems }
    setMessage = { setMessage }
    setOpenPopoverOnLoad = { setOpenPopoverOnLoad }
  />;
}

export const Configurator = connect( Combine( configurationState, userSettingsState , busyState, applicationSettingsState, messagesState, claimsDataState, userPreferencesState ),
  Combine( configurationDispatcher, userSettingsDispatcher, applicationSettingsDispatcher, errorDispatcher, messagesDispatcher, claimsDataDispatcher, userPreferencesDispatcher )
)( $ConfiguratorWrapper )