import React from 'react';
import { connect } from 'react-redux';
import { Box} from '@mui/material';
import { applicationSettingsState,claimsDataState,Combine } from '../../store';
import { IPriceProps } from '../../../types';
import { getFormattedPrice,getSymbol } from '../../services';
import { isFeatureVisible } from '../../services/ClaimsHelper';
import { isValidCurrency } from '../../services/Price';

export const $Price = ( {price,isBundled,applicationSettings, claimsData}:IPriceProps ) => {
  const currencyCode = applicationSettings?.currency.currencyCode;
  if( price === null && !isBundled ) {
    return null
  }
  if( !isFeatureVisible(claimsData.featureFlags.ListPrice)) {
    return null
  }
  return <>
    <Box alignItems="center" justifyContent="center" >
      {currencyCode && <span className={ !isValidCurrency( currencyCode ) ? 'invalidCurrency' : 'content' }>
        { getSymbol( applicationSettings?.currency.countryCode, currencyCode ) } 
      </span>}
      {currencyCode && getFormattedPrice( price )}
    </Box>
  </>
}
export const Price = connect( Combine( applicationSettingsState,claimsDataState ) )( $Price ) ;