import { appSettings } from '../settings';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';


export const NotifyAPI = {
  notify: ( action: { configurationId: string, token: string } ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/notify/save',
        { configurationId: action.configurationId },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).catch( ( err ) => {
        return {
          type: 'error-SET',
          error: {
            code: err.response.status,
            message: err.response.data,
            page: 'notify',
          }
        };
      } ),

  reset: ( configurationId: string, token: string ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/reset',
        { configurationId: configurationId },
        !appSettings.STAuth ? { headers: getHeaders( token ) } : undefined 
      )

};
