import { Dispatch } from 'react';
import { IState,IClaimsDataAction,IClaimsData,IFeatureFlags } from '../../../types';
import {FeatureFlags} from '../../data/Constants'

export const claimsDataActions = {  
  GETCLAIMS: 'claimsDataAction-GETCLAIMS',
  SETCLAIMS: 'claimsDataAction-SETCLAIMS'
}

const defaultClaimsData = {
  claims: null,
  featureFlags: FeatureFlags
} as IClaimsData

export const claimsDataDispatcher = ( dispatch: Dispatch<IClaimsDataAction> ) => ( {
  getClaims: ( token: string ) => dispatch( { type: claimsDataActions.GETCLAIMS, token } )
  
} )

export const claimsDataReducer = ( state = defaultClaimsData, action: IClaimsDataAction = { type: '' } ) => {  
  if( action.type === claimsDataActions.SETCLAIMS ) {
    return setClaimsData( action, state )
  } else{
    return state;
  }
};

const setClaimsData = ( action:IClaimsDataAction , state:IClaimsData ) => {
  const featureFlagsTemp:IFeatureFlags = {...state.featureFlags};
  const claimsData = {...action?.claims}
  if( claimsData && claimsData?.featureFlags ) {
    claimsData?.featureFlags.forEach( featureFlag => {
      if( featureFlag.name in featureFlagsTemp ) {
        featureFlagsTemp[featureFlag.name] = {...featureFlag,active:true}
      }
    } )
  }
  return { ...state, claims: claimsData,featureFlags: featureFlagsTemp}
}

export const claimsDataState = ( state: IState ) => ( { claimsData: state.claimsData } );