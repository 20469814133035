import React, { ReactElement, useState } from 'react';
import { IOnTimeoutChangerProps, IValueType } from '../../../types';

/**
 * Wrapper component to add a timeout to the onChange event of the child
 * @param {IOnTimeoutChangerProps} props the properties for the OnTimeoutChanger wrapper component
 * @returns {JSX.Element} the child component with modified onChange handling
 */
export const OnTimeoutChanger = ( { children, timeout, onChange } : IOnTimeoutChangerProps ) => {
  const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout | null>( null );

  const onTimeoutChange = ( value: IValueType, valid = true, callback: ( () => void ) | null = null ) => {
    if ( inputTimeout ) {
      clearTimeout( inputTimeout );
      setInputTimeout( null );
    }

    if( !valid ) {
      return;
    }

    setInputTimeout( setTimeout( () => {
      onChange( value );
      callback && callback();
    }, timeout ) );
  }

  return React.cloneElement( children as ReactElement, { onChange: onTimeoutChange } );
}