export const requestHeader: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'Philips_Ace_Configurator',
};

export const getHeaders = ( token:string, lang:string | null = null ) => {
  if ( lang ) {
    return { 'Authorization': `Bearer ${token}`, 'Accept-Language':lang }
  } else {
    return { 'Authorization': `Bearer ${token}` }
  }
}