import React from 'react';
import { connect } from 'react-redux';
import { userSettingsState } from '../store';
import { IVariableInputProps } from '../../types';
import { DynamicInputControl } from '.';
import { SessionStore } from '../services/SessionStore';
import { EVariableType, ESessionStore } from '../data/Constants';

/**
 * Renders the container for the dynamic input control with additional icons for locked state and short sales text.
 * @param {IVariableInputProps} props the properties for the variable input component
 * @returns {JSX.Element} the container for the dynamic input control
 */
export const $VariableInput = ( { variable, onChange,onOptionalChange }: IVariableInputProps ) => {
  const isGuarded = variable.isGuarded;
  const isGuardedTab = SessionStore.get( ESessionStore.IsGuardedTab );
  const type = variable.variableType;
  //if property is guarded show it only in guarded tab except for submodel
  //if property is non guarded and any type show it in respective tab
  return isGuarded && type !== EVariableType.Submodel && !isGuardedTab ? null : <DynamicInputControl variable={ variable } onChange={ onChange } onOptionalChange={ onOptionalChange } />
}


export const VariableInput = connect( userSettingsState )( $VariableInput );
