import { Dispatch } from 'react';
import { IMessagesState, IMessagesAction, IState, IMessages } from '../../../types';

export const MessageActions = {
  SET: 'messageAction-SET',
  DELETE: 'messageAction-DELETE',
  CLEAR: 'messageAction-CLEAR',
  SETOPENPOPOVERONLOAD: 'messageAction-SETOPENPOPOVERONLOAD'
}

const initialMessagesState = {
  messages: [],
  openPopoverOnLoad: false
} as IMessagesState;

export const messagesDispatcher = ( dispatch: Dispatch<IMessagesAction> ) => ( {
  setMessage: ( message: IMessages ) => dispatch( { type: MessageActions.SET, message: message } ),
  deleteMessage: ( index: number ) => dispatch( { type: MessageActions.DELETE, index: index } ),
  clearMessages: () => dispatch( { type: MessageActions.CLEAR } ),
  setOpenPopoverOnLoad: ( openDialog: boolean ) => dispatch( { type: MessageActions.SETOPENPOPOVERONLOAD, openDialog: openDialog } )
} )

export const messagesReducer = ( state = initialMessagesState, action: IMessagesAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case MessageActions.SET:
      newState = { ...state, messages:[ ...state.messages, action.message ] };
      return newState;
    case MessageActions.DELETE:
      const temp = [].concat( state.messages );
      temp.splice( action.index, 1 ); //To remove the message using index
      newState = { ...state, messages:[ ...temp ] };
      return newState;
    case MessageActions.CLEAR:
      newState = { ...state, ...initialMessagesState };
      return newState;
    case MessageActions.SETOPENPOPOVERONLOAD:
      newState = { ...state, openPopoverOnLoad: action.openDialog };
      return newState;
    default:
      return state;
  }
};

export const messagesState = ( state: IState ) => ( { popoverMessagesState: state.messagesState } );