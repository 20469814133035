import { Dispatch } from 'react';
import { SessionStore } from '../../services/SessionStore';
import { IApplicationSettingsAction, IState, IApplicationSettings } from '../../../types';
import { ESessionStore } from '../../data/Constants';

export const applicationSettingsActions = {
  SET: 'applicationSettings-SET',
  RESET: 'applicationSettings-RESET',
  GET: 'applicationSettings-GET',
  SETACTIVETAB: 'applicationSettings-SETACTIVETAB',
  SETSEARCHVALUES: 'applicationSettings-SETSEARCHVALUES'
}
const params = new URLSearchParams( window.location.search );
const configurationId = params.get( 'configurationId' );
const sessionStoreKey = 'application-settings';
const defaultApplicationSettings:IApplicationSettings = {
  showSectionTabs: true,
  showSummary: true,
  activeTab: 0,
  forceShowSectionTabs: false,
  forceShowSummary: false,
  pageSize: {
    isExtraSmall: false,
    isSmall: false, 
    isMedium: false,
    isLarge: false,
    isExtraLarge: false
  },
  currency:{
    'countryCode': 'US',
    'name': 'United State Dollar',
    'currencyCode':'USD',
    'locale':'en-US'
  },
  isConfigurationChanged:false,
  priceListMap:'US',
  productsActiveTab: {},
  searchCount:{searchCount:0},
  searchMatches:[]
}

const getDefaultSettings = () => {
  let storedSettings = SessionStore.get( sessionStoreKey );
  
  if ( SessionStore.get( ESessionStore.ConfigurationId ) !== configurationId ) { //reset application setting for new confguration id
    SessionStore.remove( ESessionStore.ViewId );
    return defaultApplicationSettings;
  }
  if( !storedSettings ) {
    storedSettings = defaultApplicationSettings;
  } else {
    let flag = false;
    for( const key in defaultApplicationSettings ) {
      if( !( key in storedSettings ) ) {
        flag = true;
        //set default settings if stored settings are missing any property
        storedSettings[key] = defaultApplicationSettings[key as keyof IApplicationSettings]; // add missing property in stored settings with default value
        
      }
    }
    if( flag ) { // if property is missing then only update it in session store
      SessionStore.set( sessionStoreKey, storedSettings );
    }
  }  
  return { ...storedSettings, forceShowSectionTabs: false, forceShowSummary: false };
}

export const applicationSettingsDispatcher = ( dispatch: Dispatch<IApplicationSettingsAction> ) => ( {
  setApplicationSettings: ( applicationSettings: IApplicationSettings ) => dispatch( { type: applicationSettingsActions.SET, applicationSettings } ),
  resetApplicationSettings: () => dispatch( { type: applicationSettingsActions.RESET } ),
  getApplicationSettings:( token: string )=>dispatch( { type: applicationSettingsActions.GET,token } ),
  setActiveTab: ( modelId:string, sectionID:string, tabIndex:number ) => dispatch( {type:applicationSettingsActions.SETACTIVETAB,modelId,sectionID,tabIndex} ),
  setSearchValues:( searchCount,searchMatches )=>dispatch( { type: applicationSettingsActions.SETSEARCHVALUES,searchCount,searchMatches } )
} )

export const applicationSettingsReducer = ( state = getDefaultSettings(), action: IApplicationSettingsAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case applicationSettingsActions.SET:
      newState = { ...state, ...action.applicationSettings };
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    case applicationSettingsActions.RESET:
      newState = { ...state, ...defaultApplicationSettings,productsActiveTab: {} };
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    case applicationSettingsActions.GET:
      return newState;    
    case applicationSettingsActions.SETACTIVETAB:{
      const tempSetActiveTab = {...state.productsActiveTab};
      tempSetActiveTab[`${action.modelId}`] = {sectionId:action.sectionID,tabIndex:action.tabIndex};
      newState = {...state,productsActiveTab:tempSetActiveTab}
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    }
    case applicationSettingsActions.SETSEARCHVALUES:{
      return {...state,searchCount:action.searchCount,
        searchMatches:action.searchMatches}
    }
    default:
      return state;
  }
};

export const applicationSettingsState = ( state: IState ) => ( { applicationSettings: state.applicationSettings } );