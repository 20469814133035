import React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { IMultiValueSelectControlProps } from '../../../types';
import { getNameWithCode } from '../../services';
import { connect } from 'react-redux';
import { userSettingsState } from '../../store';
import { IFeatureOrFamily } from '../../../types/IHelperTypes';
import { InfoDialogIcon } from './InfoDialogIcon';
import { gridLayout } from '../SectionLayout';
import { useTranslation } from 'react-i18next';
import { OptionalControl,Price} from '..';
import { checkIsOptionReadonly ,checkIsOptionHidden } from '../../services/ConfigurationDataHelperFunctions';

/**
 * Renders a dropdown which allows you to select more than one value.
 * Used for variables with multiple selections
 * @param {IMultiValueSelectControlProps} props the properties for the mutli select component
 * @returns {JSX.Element} the mutli select component
 */
export const $MultiValueSelectControl = ( { featureFlags, values, userSettings, onChange, onOptionalChange,variable,disabled } : IMultiValueSelectControlProps ) => {
  const {t} = useTranslation();
  const onInternalChange = ( e:React.SyntheticEvent ) => {
    onChange( e.currentTarget.getAttribute( 'value' ) || '' );
  }
  
  return <FormGroup className="w-100">
    <Grid container item xs={ gridLayout.fullWidth }>
      {values?.map( ( v,k )=> {
        const readOnly = checkIsOptionReadonly( v );
        const hidden = checkIsOptionHidden( v );
        /*
          If hidden return null. Return null will not show any input control for particular variable in UI.
          It doesn't have any impact on configuration summary, price or any other functionality of application
        */  
        if ( hidden ) {
          return null;
        }
        return <Grid key={ k } container className={ v.state.isIncompatible ? 'incompatible' : 'compatible' } item xs={ gridLayout.fullWidth } alignItems="center">
          <Grid container item xs={ gridLayout.xs.input } sm={ gridLayout.sm.input } justifyContent="left" alignItems="center">
            <FormControlLabel className={ v.isSearchValueMatched ? 'SearchValueMatched' : '' } label={ getNameWithCode( v as IFeatureOrFamily,userSettings.showCode ) } checked={ !!v.state.isAssigned } value={ v.value } onChange={ onInternalChange } control={ <Checkbox className="paddingTopBottom"/> } disabled={ disabled ? disabled : readOnly }/>
          </Grid>
          <Grid container item xs={ gridLayout.xs.infoIcon } sm={ gridLayout.sm.infoIcon } alignItems="center" justifyContent="center">
            <InfoDialogIcon variable={ v as IFeatureOrFamily }/>
          </Grid>
          <Grid container item xs={ gridLayout.xs.optional } sm={ gridLayout.sm.optional } alignItems="center" justifyContent="center">
            <OptionalControl variable={ v } onOptionalChange={ onOptionalChange } featureFamily={ variable } disabled={ disabled ? disabled : readOnly } featureFlag = { featureFlags?.Optional }/>
          </Grid>
          <Grid container item xs={ gridLayout.xs.price } sm={ gridLayout.sm.price } alignItems="right" justifyContent="right">
            <Box display={ {xs:'inline',sm:'none'} } textAlign="center">{t( 'labels.listPrice' )}:&nbsp;</Box>
            <Price price={ v.price } isBundled={ v.isBundled }/>
          </Grid>
        </Grid>
      }
      )}
    </Grid>
  
  </FormGroup>
}

export const MultiValueSelectControl = connect( userSettingsState )( $MultiValueSelectControl );