import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { ILanguageSelectionDialogPropsExt } from '../../types';
import { Combine, configurationState, userSettingsDispatcher, userSettingsState,userPreferencesState,userPreferencesDispatcher } from '../store';
import { useTranslation, withTranslation } from 'react-i18next';
import { AppAuthContext } from '../services/Contexts';
import { SessionStore } from '../services/SessionStore';
import { ESessionStore } from '../data/Constants'
import {IsAppOpenedInIframe,IsAppOpenedByExtApp } from '../services';
/**
   * Renders the Language Selection dialog where the user can select the language for the session 
   * avialable in product Model package
   * @param {ILanguageSelectionDialogPropsExt} props the properties for the preferences dialog
   * @returns {JSX.Element} the preferences dialog
   */
export const $LanguageSelectionDialog = ( props: ILanguageSelectionDialogPropsExt ) => {
  const { userPreferences, updateUserPreferences, setUserPreferences, configuration, languageSelection, setLanguageSelection } = props;
  const [isPopUpOpen, setLanguageInPopUp] = React.useState( !languageSelection );
  const [showButton, setShowButton] = React.useState( false );
  const [userPreference, setUserPreference] = React.useState( false );
  const [languageSelected, setLanguageSelected] = React.useState<{[key:string]:any}|undefined>( {code:'en-US', name:'English'} );
  const { i18n, t } = useTranslation();
  const token = useContext( AppAuthContext );
  const availableLanguages = configuration?.pmLanguages;
  const onToggleSetDefault = () =>{ 
    userPreference ? setUserPreference( false ) : setUserPreference( true );
  }
  const onLanguageChange = async ( event:SelectChangeEvent<string> ) => {
    const lang = availableLanguages?.find( avLang => avLang.name === event.target.value.toString() );
    setLanguageSelected( lang );
    setShowButton( true );
  };
  const handleClose = () => {
    setLanguageInPopUp( false );
    setUserPreferences( { language: languageSelected?.code, showCode: userPreferences.showCode } );
    if( IsAppOpenedInIframe() || IsAppOpenedByExtApp() ) {
      SessionStore.set( ESessionStore.Language,languageSelected?.code.split( '-' )[0] );
      SessionStore.set( ESessionStore.Country,languageSelected?.code.split( '-' )[1] );
    }
    languageSelected && languageSelected.code && i18n.changeLanguage( languageSelected?.code.replace( '-','_' ) );
    if( userPreference ) {
      updateUserPreferences( token, { language: languageSelected?.code, showCode: userPreferences.showCode } );
    }
    setLanguageSelection( true );
  }
  
  return(
    <>
      <Dialog open={ isPopUpOpen } maxWidth="xs" fullWidth className="languageSelectionDialogStyle common-dialog-style">
        <DialogTitle className="header">{t( 'languageSelection.selectLanguage' )}</DialogTitle>
        <DialogContent> 
          <FormControl fullWidth className="form-control" >
            <InputLabel>{t( 'languageSelection.language' )} </InputLabel>
            <Select color="primary" label={ t( 'languageSelection.language' ) }
              onChange={ onLanguageChange } data-testid="languageSelection-dialog"
            >
              { availableLanguages?.map( ( value ) => 
                <MenuItem value={ value.name } key={ value.code } >{ value.name + ' (' + value.code + ')' }</MenuItem> )
              }
            </Select>
          </FormControl>
          <FormControlLabel className="form-control"
            value="start"
            control={ <Switch
              disabled = { !showButton } 
              color="primary" 
              checked={ userPreference } 
              onChange={ onToggleSetDefault }
            /> }
            label={ t( 'languageSelection.setPreference' ) }
            labelPlacement="start"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled = { !showButton } className="text-capitalize" onClick={ handleClose } color="primary" data-testid="handleClose">
            {t( 'button.ok' )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
  
export const LanguageSelectionDialog = withTranslation()( connect( Combine( configurationState, userSettingsState,userPreferencesState ), Combine( userSettingsDispatcher,userPreferencesDispatcher ) )( $LanguageSelectionDialog ) )