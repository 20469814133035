import { Info } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { configurationDispatcher } from '../../store';
import { IInfoDialogIconProps } from '../../../types';
import { hasOrderingInstruction } from '../../services';

export const $InfoDialogIcon = ( {variable,setInfoDialogVariable,type}:IInfoDialogIconProps ) => {
  const getClassName = ()=>{
    let iconClass = ''
    let iconClassHighlight = ''
    iconClass = type && type === 'FF' ? 'familyInfoDialogIcon' : 'infoDialogIcon';
    const hasHighLight = hasOrderingInstruction( variable.properties ? variable.properties : [] ) || variable?.hasORI ;
    iconClassHighlight = hasHighLight ? 'infoDialogIconHighlight' : '';
    return iconClass + ' ' + iconClassHighlight
  }
  
  return setInfoDialogVariable && <IconButton data-testid="btn-info-dialog" onClick={ () => setInfoDialogVariable( variable ) } className={ `${getClassName( )} ` }>
    <Info color="primary" />
  </IconButton> || null;
}

export const InfoDialogIcon = connect( null,configurationDispatcher )( $InfoDialogIcon );