
import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ILoadingScreenProps } from '../../types';
import { busyState,configurationState,Combine } from '../store';
/**
 * An overlay with a loading circle which is displayed if there is some background task running
 * @param {ILoadingScreenProps} props the properties for the loading screen component
 * @returns {JSX.Element} the overlay with the loading circle component
 */
export const $LoadingScreen = ( { busy,configuration } : ILoadingScreenProps ) => {
  const {t} = useTranslation();
 
  return <Backdrop open={ busy } >
    <><CircularProgress /> &nbsp; {configuration?.loaderMessage ? t( 'loaderMessage.' + configuration.loaderMessage ) : t( 'loaderMessage.default' )} ...</>
  </Backdrop>;
}

export const LoadingScreen = connect( Combine( busyState,configurationState ) )( $LoadingScreen );