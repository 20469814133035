import { FormControl, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Combine, configurationDispatcher, configurationState, applicationSettingsDispatcher, applicationSettingsState } from '../../store';
import { Build } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { UrlHelper } from '../../services';
import { NumericControl, OnTimeoutChanger } from '..';
import { IFeatureOrFamily, ISubModelControlProps, ISubModelControlWrapperProps, IValueType } from '../../../types';
import { SessionStore } from '../../services/SessionStore';
import { InfoDialogIcon } from './InfoDialogIcon';
import { InCompleteIcon } from './InCompleteIcon';
/**
 * Renders a numeric input and a configure button
 * @param {ISubModelControlProps} props the properties for the submodel control box
 * @returns {JSX.Element} the submodel control box
 */
export const $SubModelControl = ( { configuration, disabled, onChange, label, value, variable, applicationSettings, setApplicationSettings }: ISubModelControlProps ) => {
  const [redirect, setRedirect] = useState( false );
  const [isValid, setIsValid] = useState( true );
  const subModelId = configuration.savedConfiguration?.modelContext.subModels.find( m => m.subModelVariableIds?.includes( variable.id ) )?.id;

  if ( !subModelId ) {
    console.warn( `subModel id not found for ${variable.id}` );
    return null;
  }

  const currentSubModel = UrlHelper.getSearchParameter( 'model' );
  const modelPath = currentSubModel ? [...currentSubModel.split( '.' ), subModelId].join( '.' ) : subModelId;
  const searchParams = UrlHelper.addSearchParameter( 'model', modelPath );

  const onInternalChange = ( newValue: IValueType ) => onChange && onChange( newValue );
  const onConfigure = () => {
    const currentActiveTab = applicationSettings.productsActiveTab[modelPath] === undefined ? 0 : applicationSettings.productsActiveTab[modelPath].tabIndex;
    setApplicationSettings( { ...applicationSettings, activeTab: currentActiveTab } );
    SessionStore.set( 'isGuardedTab', false );
    setRedirect( true );
  };
  const getSubModelIcon = () => <Tooltip title="Configure" placement="right-start">
    <span>
      <IconButton disabled={ !value || disabled || !isValid } onClick={ onConfigure } className="m-0 submodelIcon">
        <Build color={ !value || disabled || !isValid ? 'disabled' : 'primary' } fontSize="medium" />
      </IconButton>
    </span>
  </Tooltip>

  if ( redirect ) {
    return <Navigate to={ `${window.location.pathname}?${searchParams}` } replace={ true }/>
  }

  return <fieldset className={ variable.isSearchValueMatched ? 'inputFieldsetWithSearchValueMatched' : 'inputFieldset' }>
    <Tooltip title={ label } placement="right-start">
      <legend className="inputLegend"><span>{label}</span> {variable.complete === false ? <InCompleteIcon/> : ''}
        <InfoDialogIcon variable={ variable as IFeatureOrFamily } type="FF"/>
      </legend>
    </Tooltip>
    <FormControl fullWidth variant="outlined" disabled={ disabled }>
      <OnTimeoutChanger timeout={ 1000 } onChange={ onInternalChange }>
        <NumericControl configuration={ configuration } variable={ variable } label={ label } value={ value } onCallback={ setIsValid } subModelIcon={ () => getSubModelIcon() } disabled={ disabled }/>
      </OnTimeoutChanger>
    </FormControl>
  </fieldset>
}

/**
 * Checks for required props and renders the submodel control
 * @param {ISubModelControlWrapperProps} props the properties for the submodel control wrapper component
 * @returns {JSX.Element} the submodel control component or null if required properties are missing
 */
const $SubModelControlWrapper = ( { configuration, disabled, onChange, label, value, variable, applicationSettings, setApplicationSettings }: ISubModelControlWrapperProps ) => {
  if ( !configuration || !applicationSettings || !setApplicationSettings ) {
    return null;
  }

  return <$SubModelControl
    configuration={ configuration }
    applicationSettings={ applicationSettings }
    setApplicationSettings={ setApplicationSettings }
    disabled={ disabled }
    onChange={ onChange }
    label={ label }
    value={ value }
    variable={ variable }
  />;
}

export const SubModelControl = connect(
  Combine( configurationState, applicationSettingsState ),
  Combine( configurationDispatcher, applicationSettingsDispatcher )
)( $SubModelControlWrapper );
