import { AppBar, Box, Toolbar, Tooltip,IconButton } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { applicationSettingsDispatcher, applicationSettingsState, Combine, configurationDispatcher, configurationState, productSettingsDispatcher } from '../store';
import { HeaderLogin } from '.';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import { EConfigurationType, EPageNames } from '../data/Constants';
import { IHeaderProps } from '../../types';
import {GetToken, IsAppOpenedInIframe,navigateToHome,showHeaderFooter} from '../services';
import { useNavigate } from 'react-router-dom';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';

/**
 * Renders the header which also includes the buttons to hide/show the TabDrawer and InfoPanel
 * @param {IHeaderProps} props the properties for the header component
 * @returns {JSX.Element} the header component
 */
export const $Header = ( {configuration,resetConfiguration,resetApplicationSettings,resetMyConfigurations,changeLandingTab}:IHeaderProps ) => { 
  const {token} = GetToken()
  if( !configuration || IsAppOpenedInIframe() || showHeaderFooter() ) {
    return null;
  }
  const { t } = useTranslation();
  const navigate = useNavigate();
  const philipsLogo = '../../public/png/logo.png';
  let displayWarningMessage = null;
  if( token && configuration.savedConfiguration?.state === EConfigurationType.Temp ) {
    displayWarningMessage = <><h2 className="m-0"> {t( 'tempConfiguration.temporaryConfiguration' )}</h2>  <span>{t( 'tempConfiguration.tempWarningMessage' )}</span></> ;
  }else if( token && configuration.savedConfiguration?.state === EConfigurationType.ReadOnly || configuration.access === EConfigurationType.ReadOnly ) {
    displayWarningMessage = <> <h2 className="m-0"> {t( 'readOnlyModeConfiguration.readOnlyConfiguration' )}</h2>
      <span>{t( 'readOnlyModeConfiguration.readOnlyWarningMessage' )}</span></>
  }
  return (
    <AppBar className="headerStyle root" position="static">
      <Toolbar className="toolbar">
        <Box className="toolbarSection">
          { IsDefaultFlowInSessionStore() && [EPageNames.LandingPage].indexOf( location.pathname ) < 0 ?
            <img className="zoom80 headerLogo" src={ philipsLogo } onClick={ ()=> navigateToHome( {resetConfiguration,resetApplicationSettings,resetMyConfigurations,changeLandingTab,navigate} ) } /> :
            <img className="zoom80 " src={ philipsLogo } />}
        </Box>
        {displayWarningMessage ? <div className="rowFlex tempWarningMessage">
          <span className="colFlex warningIcon"><WarningIcon className="warningSize"/></span> &nbsp;
          <div className="warningMessage">
            {displayWarningMessage}
          </div>
        </div> : '' }
        <Box className="toolbarSection">
          <Tooltip className="tooltip" title={ t( 'tooltip.help' ) }><IconButton className="button-icon" > <HelpIcon/>
          </IconButton>
          </Tooltip>
          <HeaderLogin />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export const Header = connect(
  Combine( configurationState,applicationSettingsState ),
  Combine( configurationDispatcher,applicationSettingsDispatcher,productSettingsDispatcher )
)( $Header ) ;
