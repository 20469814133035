import './settings';
import './api/APIConfig';
import '../style/index.less';
import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { AppStore } from './store';
import { theme } from '../style/theme';
import { AuthComponent } from './components';
import './i18n';//for localization
import { createRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import cspSettings from '../settings/csp/csp.settings.json';
import settings from '../public/app.settings.json';
import {Buffer} from 'buffer';
const container = document.getElementById( 'root' )!;
const root = createRoot( container );
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const cacheValue = cspSettings.Enable ? createCache( {
  key:'nonce',
  nonce:Buffer.from( 'secretnoncephilips', 'base64' ).toString( 'base64' ) //eslint-disable-line
} ) : createCache( {key:'philips'} );
root.render(
  <CacheProvider value={ cacheValue }>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={ AdapterMoment }>
        <ThemeProvider theme={ theme }>
          <Provider store={ AppStore }>
            <AuthComponent />
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </CacheProvider>
  
);
const getVersion = async () => {
  return fetch( '../public/versioninfo.json', { cache: 'no-store' } ).then( ( response ) =>
    response.json()
  );
  
};
if( settings.EnableServiceWorker ) {
  await getVersion().then( async ( data ) => {
    const version = Array.isArray( data ) ? data[0].Version : data.Version;
    const oldVersion = localStorage.getItem( 'version' );
    if( version !== oldVersion ) { //clear cache and re-register service worker if version is old
      serviceWorkerRegistration.unregister();
      if( caches ) {
        await caches.keys().then( ( names ) => {
          for ( const name of names ) {
            caches.delete( name );
          }
        } );
        window.location.reload();
        localStorage.setItem( 'version',version )
      }
    }else{
      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://cra.link/PWA
      serviceWorkerRegistration.register();
    }
  } );
}else{
  serviceWorkerRegistration.unregister();
}


