import React, { useEffect, useState } from 'react';
import { Combine, configurationDispatcher, configurationState, productSettingsState, productSettingsDispatcher } from '../store';
import { connect } from 'react-redux';
import { Box, Tab, FormControlLabel, Checkbox } from '@mui/material';
import { useAuth } from 'oidc-react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useTranslation } from 'react-i18next';
import { DefaultFlow, ESessionStore, ETabValue } from '../data/Constants';
import { Unauthenticated, UnsupportedBrowser } from '.';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ILandingPage, IHierarchy, IHierarchyBusinessUnits, IGetOnChangeProps } from '../../types';
import { SessionStore } from '../services/SessionStore';
import { appSettings } from '../settings';
import { ProductPortfolio } from './ProductPortfolio';
import { MyConfigurations } from './MyConfigurations';

function selectCategory ( checked:boolean, props:IGetOnChangeProps ) {
  const { nodes, selected, parentNode,tabCategory,selectedChannel } = props
  const allNode: string[] = [nodes.code, ...getAllChild( nodes,parentNode )];
  let array = []
  if( tabCategory === ETabValue.Product ) {
    array = checked
      ? [...selected, ...allNode]
      : selected.filter( value => !allNode.includes( value ) ) ;
  } else {
    array = checked
      ? [...selectedChannel, ...allNode]
      : selectedChannel.filter( value => !allNode.includes( value ) ) ;
  }
  
  array = array.filter( ( v, i ) => array.indexOf( v ) === i );    
  if( parentNode ) {
    const collectChildIds:string[] = getAllChild( parentNode );
    const parentCheck = collectChildIds.every( val => array.includes( val ) );
    if( parentCheck ) {
      array.push( parentNode.code )
    } else {
      if( array.indexOf( parentNode.code ) !== -1 ) {
        array.splice( array.indexOf( parentNode.code ),1 )
      }
    }
  }
  return array;
}

// gets All Child Codes if parentNode is checked   
function getAllChild( nodes: IHierarchy, parentNode?:IHierarchy ) {
  let array: string[] = [];
  if ( nodes === null ) {
    return [];
  }
  if ( Array.isArray( nodes.businessUnits ) ) {
    nodes.businessUnits.forEach( ( node:IHierarchyBusinessUnits ) => {      
      array.push( node.code );
      array = array.concat( node.articleGroups )
    } );
  }
  if ( Array.isArray( nodes.applications ) ) {
    nodes.applications.forEach( ( node:IHierarchyBusinessUnits ) => {      
      array.push( node.code );
      array = array.concat( node.code )
    } );
  } 
  if( parentNode ) {
    array = nodes.articleGroups ? array.concat( nodes.articleGroups ) : array.concat( nodes.code )
  }
  return array;
}
  
function getOnChange( checked:boolean, props:IGetOnChangeProps ) {
  const { configuration, setSelected, tabCategory, setSelectedChannel, getProductCatalog, token, getMyConfigurations, updateProductCatalogDetails, updateMyConfigPageDetails, productSettings } = props
  if( tabCategory === ETabValue.Product ) {
    const array = selectCategory( checked,props )      
    setSelected( array );
    updateProductCatalogDetails( 1, productSettings.productCatalogDetails.limit, productSettings.productCatalogDetails.totalRecords, array )  
    getProductCatalog( token, 1, productSettings.productCatalogDetails.limit, configuration.productSearchValue, array )
    
  } else {
    const array = selectCategory( checked,props )
    setSelectedChannel( array );
    updateMyConfigPageDetails( 1, productSettings.myConfigPageDetails.limit, productSettings.myConfigPageDetails.totalRecords, array )
    getMyConfigurations( token, 1, productSettings.myConfigPageDetails.limit, configuration.productSearchValue, array )
  }
}

const traverseInnerNodes = ( tabCategory, nodes, renderTreeProps ) => {
  if( tabCategory === ETabValue.Product ) {
    if( Array.isArray( nodes.businessUnits ) ) {
      return nodes.businessUnits.map( ( node: IHierarchyBusinessUnits ) => renderTree( node,renderTreeProps,tabCategory,nodes ) )
    } else {
      return null
    }
  } else {
    if( Array.isArray( nodes.applications ) ) {
      return nodes.applications.map( ( node: IHierarchyBusinessUnits ) => renderTree( node,renderTreeProps,tabCategory,nodes ) )
    } else {
      return null
    }
  } 
}

const renderTree = ( nodes: IHierarchy, renderTreeProps, tabCategory:string, parentNode?: IHierarchy ) => {
  const { selected, selectedChannel, configuration, setSelected, setSelectedChannel, getProductCatalog, token, getMyConfigurations, updateProductCatalogDetails, updateMyConfigPageDetails, productSettings } = renderTreeProps
  return <TreeItem
    key={ nodes.code }
    nodeId={ nodes.code }
    className = "filter-container"
    data-testid ="filter-container"
    label={
      <FormControlLabel
        control={
          <Checkbox
            checked={ tabCategory === ETabValue.Product ? selected.some( item => item === nodes.code ) : selectedChannel.some( item => item === nodes.code ) }
            onChange={ event =>{
              getOnChange( event.currentTarget.checked,{ nodes, configuration, selected, setSelected, parentNode, tabCategory, selectedChannel, setSelectedChannel, getProductCatalog, token, getMyConfigurations, updateProductCatalogDetails, updateMyConfigPageDetails, productSettings} )
            }
            }
            onClick={ e => e.stopPropagation() }
            className = "filter-checkbox"
            data-testid ="filter-checkbox"
          />
        }
        label={ tabCategory === ETabValue.Product ? <>{nodes.description}</> : <>{nodes.name}</> }
        key={ nodes.code }
        className = "filter-label"
      />
    }
  >
    { traverseInnerNodes( tabCategory,nodes,renderTreeProps )}
  </TreeItem>
} ; 

const renderProductHierarchy = ( prodHierarchy:IHierarchy[], treeProps ,t:( arg: string, arg2?: object ) => string,tabCategory:string ) => {  
  return prodHierarchy.length < 1 ? <span className="noDataAvailable-label" >{t( 'landingPage.noDataAvailable' )}</span> : prodHierarchy.map( val => renderTree( val,treeProps,tabCategory ) )
}

const tabChangeWithSearchValue = ( tabValue, currentTabValue, configuration, token, recordsPP, channelSelected, setConfigOrCatalog ) => {
  if( tabValue === currentTabValue ) {
    if( configuration.productSearchValue ) {
      return setConfigOrCatalog( token, 1, recordsPP, '', channelSelected ) 
    } else{
      return null
    }
  }
}

function $LandingPage( props: ILandingPage ) {
  const { configuration, productSettings, getProductCatalog, getMyConfigurations, updateMyConfigPageDetails, updateProductSearchValue, changeLandingTab } = props;
  const auth = useAuth();
  const token = auth.userData?.access_token || '';
  const {t} = useTranslation();

  const [prodHierarchy, setProdHierarchy] = useState<IHierarchy[]>( [] );
  const [tabValue, setTabValue] = useState<string|null>( ETabValue.Product );
  const [isDesktop, setIsDesktop] = useState( window.innerWidth > 1450 );

  SessionStore.set( ESessionStore.Flow,DefaultFlow );
  SessionStore.set( ESessionStore.Scope,appSettings.ApiKey );


  const handleTabChange = ( ...params:[React.BaseSyntheticEvent, string] ) => {
    setTabValue( params[1] );
    updateProductSearchValue( '' );
    changeLandingTab( true, tabValue === ETabValue.Product ? ETabValue.MyConfiguration : ETabValue.Product );
    tabChangeWithSearchValue( tabValue, ETabValue.MyConfiguration, configuration, token, productSettings.myConfigPageDetails.limit, productSettings.myConfigPageDetails.channel, getMyConfigurations )
    tabChangeWithSearchValue( tabValue, ETabValue.Product, configuration, token, productSettings.productCatalogDetails.limit, productSettings.productCatalogDetails.agCodes, getProductCatalog )
  };

  const updateMedia = () => {
    setIsDesktop( window.innerWidth > 1450 );
  };

  useEffect( () => {
    window.addEventListener( 'resize', updateMedia );
    return () => window.removeEventListener( 'resize', updateMedia );
  } );
 
  if ( !auth || !auth.userData ) {
    return <Unauthenticated />;
  }
  

  return <Box className="landing-Page">
    <TabContext value={ tabValue || ETabValue.Product }>
      
      <Box className="dialog-box">
        <TabList onChange={ handleTabChange } >
          <Tab className="dialog-tab text-capitalize" data-testid ="product-portfolio"
            value={ ETabValue.Product } label={ t( 'landingPage.productPortfolio' ) }
          />
          <Tab className="dialog-tab text-capitalize" data-testid ="my-configuration"
            value={ ETabValue.MyConfiguration } label={ t( 'landingPage.myConfigurations' ) }
          />
        </TabList>
      </Box>

      <TabPanel value={ ETabValue.Product } className="product-portfolio" >
        <ProductPortfolio prodHierarchy={ prodHierarchy } setProdHierarchy={ setProdHierarchy } renderProductHierarchy={ renderProductHierarchy } />        
      </TabPanel>
      
      <TabPanel className="myConfiguration-tab" value={ ETabValue.MyConfiguration } >
        <MyConfigurations isDesktop={ isDesktop } renderProductHierarchy={ renderProductHierarchy } getMyConfigurations={ getMyConfigurations } updateMyConfigPageDetails={ updateMyConfigPageDetails } changeLandingTab={ changeLandingTab }/>
      </TabPanel>

    </TabContext>
    <UnsupportedBrowser/>
  </Box>
}

export const LandingPage = connect(
  Combine( configurationState, productSettingsState ),
  Combine( configurationDispatcher, productSettingsDispatcher )
)( $LandingPage );