import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { claimsDataState } from '../store';
import {ISupportPageProps} from '../../types';
import { Unauthenticated,Unauthorized,UnsupportedBrowser } from '.';
import { EErrorTypes } from '../data/Constants'
import { useTranslation } from 'react-i18next';


//  * Renders a support message for users

const ComponentType = ( state,t,supportDetail ) => {  
  const supportMsg = <h4>{supportDetail ? supportDetail.message : null }</h4>
  switch( state.type ) {
    case EErrorTypes.NotAuthenticated : return <><Unauthenticated/>{supportMsg}</> ;
    case EErrorTypes.NotAuthorized : return <><Unauthorized/>{supportMsg}</>;
    case EErrorTypes.ContactUS : return <h4>{supportDetail?.message ? supportDetail.message : t( 'errorMessages.supportDefault' ) }</h4>;
    case EErrorTypes.InvalidConfiguration : return <><h4>
      {state.code === 404 ? state.message : t( 'errorMessages.invalidConfiguration' )}
    </h4>{supportMsg}</>
    default: return null;
  }
}

export const $ErrorPage = ( {claimsData,type}:ISupportPageProps ) => {
  const [supportDetail,setSupportDetail] = useState<{message:string,url:string} | null>( null ) ;
  const {state}:any = useLocation();
  const {t} = useTranslation();
  useEffect( ()=>{    
    if( claimsData.claims?.supportDetails ) {
      setSupportDetail( claimsData.claims.supportDetails )
    }    
  },[claimsData.claims] )

  if( type ) {
    switch( type ) {
      case EErrorTypes.Unsupported : return <><UnsupportedBrowser /></> ;
      case EErrorTypes.NotAuthorized : return <><Unauthorized/></>;
      default: return null;
     
    }
  }
  return (
    <div> 
      {state && ComponentType( state,t,supportDetail )}
      <a href={ supportDetail?.url ? supportDetail.url : '' }>{supportDetail?.url ? supportDetail.url : null }</a>
    </div>
  );
};

export const ErrorPage = connect( claimsDataState )( $ErrorPage );