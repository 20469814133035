import React from 'react';
import { Configurator, InfoContainer, TabDrawer, Unauthenticated } from '.';
import { useAuth } from 'oidc-react';
import { authSettings } from '../api/OidcSettings';
import { ConfigurationValidationDialog } from './ConfigurationValidationDialog';
import { UrlHelper,IsAppOpenedInIframe,IsAppOpenedByExtApp } from '../services';
import { AppAuthContext } from '../services/Contexts';
import { ESessionStore, EUrlParams } from '../data/Constants';
import { SessionStore } from '../services/SessionStore';
/**
 * Container to render the home screen
 * @param {IHomeProps} props the properties for the Home component 
 * @returns {JSX.Element} the home screen which includes the TabDrawer, Configurator and Infocontainer
 */
export const $Home = ( ) => {
  let token = '';
  if ( !authSettings.Developer_Mode && !IsAppOpenedInIframe() && !IsAppOpenedByExtApp() ) {
    const auth = useAuth();
    if ( !auth || !auth.userData ) {
      return <Unauthenticated />;
    }
    token = auth.userData.access_token;
  }else{
    token = UrlHelper.getSearchParameter( EUrlParams.Token ) || SessionStore.get( ESessionStore.Token );
  }

  if( window.location.search.includes( 'session_state' ) ) {
    //return null if authentication search params are not removed yet
    return null;
  }

  return (
    <>
      <AppAuthContext.Provider value={ token }>
        <TabDrawer />
        <Configurator />
        <InfoContainer />
        <ConfigurationValidationDialog />
      </AppAuthContext.Provider>
    </>
  );
};

export const Home = $Home ;
