export const UrlHelper = {
  getSearchParameter: ( key: string ) => {
    const search = window.location.search;
    const params = new URLSearchParams( search );
    return params.get( key );
  },
  addSearchParameter: ( key: string, value: string ) => {
    const search = window.location.search;
    const params = new URLSearchParams( search );
    params.set( key, value );
    return params.toString();
  },
  deleteSearchParameter: ( key: string ) => {
    const search = window.location.search;
    const params = new URLSearchParams( search );
    params.delete( key );
    return params.toString();
  },
  createSearchParameter: ( key: string, value: string ) => {
    const params = new URLSearchParams();
    params.set( key, value );
    return params.toString();
  }
}