import { ClickAwayListener, Drawer, PaperProps, Tab, Tabs, IconButton } from '@mui/material';
import React, {useEffect} from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Combine, configurationState, applicationSettingsDispatcher, applicationSettingsState } from '../store';
import { getSelectableSections, isChildOf, getProductId, UrlHelper } from '../services';
import { ESectionId, ESessionStore, EUrlParams } from '../data/Constants';
import { ITabDrawerProps, ITabDrawerWrapperProps,IApplicationSettings, ISection } from '../../types';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { SessionStore } from '../services/SessionStore';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { InCompleteIcon } from './InputComponents/InCompleteIcon';

const StyleConstants = {
  extraSmallHeaderHeight: '89px',
  headerHeight: '105px'
};

const getSXPaperProps = ( applicationSettings:IApplicationSettings )=>{
  return {
    position: applicationSettings.pageSize.isSmall ? 'absolute' : 'initial',
    transition: '.15s',
    boxShadow: applicationSettings.pageSize.isSmall ? '8px 0 8px -8px black' : 'none',
    top: applicationSettings.pageSize.isExtraSmall ? StyleConstants.extraSmallHeaderHeight : StyleConstants.headerHeight,
    bottom: '0',
    height: applicationSettings.pageSize.isSmall ? 'unset' : '100%'
  }
}
function showTab( applicationSettings:IApplicationSettings ) {
  return !applicationSettings.pageSize.isSmall && applicationSettings.showSectionTabs || applicationSettings.pageSize.isSmall && applicationSettings.forceShowSectionTabs;
}

function setDefaultActiveTab( selectableSections:ISection[],applicationSettings:IApplicationSettings,modelPath:string|null,productModel:string,setActiveTab:( id:string,sectionId:string,value:number ) => void ) {
  if( selectableSections.length > 0 ) { 
    if( modelPath && !applicationSettings.productsActiveTab[modelPath] ) {
      setActiveTab( modelPath, selectableSections[0].id, 0 );
      
    } else if( !applicationSettings.productsActiveTab[productModel] ) {
      setActiveTab( productModel, selectableSections[0].id, 0 )        
    }
  }

}

function changeTab( selectedSectionID:string,value:number,tabID:string, productModel:string,modelPath:string|null,setActiveTab:( id:string,sectionId:string,value:number ) => void ) {
  if( modelPath ) {
    setActiveTab( modelPath,tabID, value )
  } else {
    setActiveTab( productModel,tabID, value )
  }
  SessionStore.set( ESessionStore.IsGuardedTab, selectedSectionID === ESectionId.Guarded );
}

function tabs( configuration,selectableSections,tabIndex ) {
  if ( !configuration.data?.sections ) {
    return []
  } else {
    return selectableSections?.map( s => 
      <Tab key={ `${s.id}-${tabIndex}` } id={ s.id } label={ <span>{s.name} {!s.isComplete ? <InCompleteIcon/> : ''}</span> }
        value={ tabIndex++ } className={ s.isSearchValueMatched ? 'tab-drawer-SearchValueMatched' : 'tab-drawer-tabs' } iconPosition="end" 
        icon={ s.id === ESectionId.Guarded ? <LockOutlinedIcon /> : '' }
      /> )
  }
}

/**
 * Renders the section tabs on the left side on the home screen.
 * Gets automatically hidden on smaller screens
 * @param {ITabDrawerProps} props the properties for the tab drawer component
 * @returns {JSX.Element} the tab drawer component
 */
export const $TabDrawer = ( { configuration, applicationSettings, setApplicationSettings, setActiveTab }: ITabDrawerProps ) => {
  const {t} = useTranslation();
  const productModel = getProductId();
  const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );    
  const show = showTab( applicationSettings );
  const {sections:selectableSections} = getSelectableSections( configuration );

  const onTabChange = ( _: React.SyntheticEvent<Element, Event>, value : number ) =>{
    setApplicationSettings( { ...applicationSettings, activeTab: value} );
    changeTab( selectableSections[value]?.id,value,tabs( configuration,selectableSections,tabIndex )[value].props.id,productModel,modelPath,setActiveTab );      
  };

  const onClickAway = ( e: MouseEvent | TouchEvent ) => !isChildOf( e.target as HTMLElement, 'tabDrawerToggle' ) && 
  applicationSettings.forceShowSectionTabs && 
  setApplicationSettings( { ...applicationSettings, forceShowSectionTabs: false } );


  const paperProps: Partial<PaperProps<'div', unknown>> = {
    sx: getSXPaperProps( applicationSettings ),
    className: classNames( 'w-0', { ['drawerOpen']: show } )
  };

  const tabIndex = 0;

  const DrawerHeader = styled( 'div' )( ( ) => ( {
    display: 'flex',
    alignItems: 'center'
  } ) );

  useEffect( () => {    
    setDefaultActiveTab( selectableSections,applicationSettings,modelPath,productModel,setActiveTab );   
  },[ selectableSections.length] )
  useEffect( () => {   
    if( configuration.productSearchValue !== '' ) { 
      const index = selectableSections.findIndex( s => s.isSearchValueMatched === true );
      SessionStore.set( ESessionStore.IsGuardedTab, selectableSections[index]?.id === ESectionId.Guarded );
      setApplicationSettings( { ...applicationSettings, activeTab: index !== -1 ? index : applicationSettings.activeTab } );
    }
  },[ configuration.productSearchValue] )

  const onToggleDrawer = () => {
    if ( applicationSettings.pageSize.isSmall ) {
      setApplicationSettings( {
        ...applicationSettings,
        forceShowSectionTabs: !applicationSettings.forceShowSectionTabs,
        forceShowSummary: false,
      } );
    } else {
      setApplicationSettings( {
        ...applicationSettings,
        showSectionTabs: !applicationSettings.showSectionTabs,
      } );
    }
  };


  return <>{!show ? <div className="tabDrawerMini">
    <IconButton id="tabDrawerToggle" onClick={ onToggleDrawer } >  <KeyboardDoubleArrowRightIcon className={ 'pinIcon' }/></IconButton>
   
    <span className="navigateTabLabel rotate180" onClick={ onToggleDrawer }>{t( 'tabSection.navigation' )}</span>
  </div> :
    <>
      <ClickAwayListener onClickAway={ onClickAway }>
        <Drawer variant="permanent" open PaperProps={ paperProps } className="h-100">
          <DrawerHeader>
            <IconButton onClick={ onToggleDrawer } className="left-arrow" data-testid="left-arrow">
              <KeyboardDoubleArrowLeftIcon className={ 'pinIcon' }/>
            </IconButton>
          </DrawerHeader>
          
          <Tabs value={ applicationSettings.activeTab } onChange={ onTabChange } orientation="vertical" variant="scrollable" scrollButtons={ false } visibleScrollbar className="tab-toggle">
            { tabs( configuration,selectableSections,tabIndex ) }
          </Tabs>
        </Drawer>
      </ClickAwayListener>
    </>
  }
  </>
}

/**
 * Checks for required props and renders the tab drawer
 * @param {ITabDrawerWrapperProps} props the properties for the tab drawer wrapper component
 * @returns {JSX.Element} the tab drawer component or null if required properties are missing
 */
const $TabDrawerWrapper = ( { configuration, applicationSettings, setApplicationSettings, setActiveTab } : ITabDrawerWrapperProps ) => {
  if( !configuration || !applicationSettings || !setApplicationSettings ) {
    return null;
  }

  return <$TabDrawer 
    configuration={ configuration } 
    applicationSettings={ applicationSettings } 
    setApplicationSettings={ setApplicationSettings }
    setActiveTab = { setActiveTab }  
  />;
}

export const TabDrawer = connect( Combine( configurationState, applicationSettingsState ),applicationSettingsDispatcher )( $TabDrawerWrapper );