import { Dispatch } from 'react';
import { DefaultUserSettings } from '../../data/Constants';
import { IUserPreferencesAction, IState, IUserPreferences } from '../../../types';

export const userPreferencesActions = {
  SET: 'userPreferencesActions-SET',  
  GET: 'userPreferencesActions-GET',
  UPDATE:'userSettingsActions-UPDATE'  
}

const defaultUserPreferences = {...DefaultUserSettings,preferenceChanged:false} 

export const userPreferencesDispatcher = ( dispatch: Dispatch<IUserPreferencesAction> ) => ( {
  setUserPreferences: ( userPreferences: IUserPreferences ) => dispatch( { type: userPreferencesActions.SET, userPreferences } ),
  getUserPreferences:( token: string )=>dispatch( { type: userPreferencesActions.GET,token } ),
  updateUserPreferences:( token: string,request:any )=>dispatch( { type: userPreferencesActions.UPDATE,token,request } ),  
} )

export const userPreferencesReducer = ( state = defaultUserPreferences , action: IUserPreferencesAction = {
  type: '',
  apiType: ''
} ) => {
  let newState = {...state};
  switch ( action.type ) {
    case userPreferencesActions.SET:
      newState = { ...state, ...action.userPreferences };
      return newState;
    case userPreferencesActions.GET:
      newState = { ...state, ...action.data };
      return newState;
    case userPreferencesActions.UPDATE:
      return { ...state, ...action.request };
    default:
      return state;
  }
};

export const userPreferencesState = ( state: IState ) => ( { userPreferences: state.userPreferences } );