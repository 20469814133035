import React from 'react';
import { IconButton, Tooltip,Button } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';

export const SaveAndClose = ( {onNotifyDialog,disableButton,getClassName} ) =>{ 
  const {t} = useTranslation();
  const getClassNameButton = ( )=>{
    if( disableButton ) {
      return 'disable-product-action'
    }else {
      return 'add-product-action'
    }
  }
  return !IsDefaultFlowInSessionStore() ? 
    <> <Button variant="outlined" startIcon={ <AddShoppingCartIcon className="cart-icon" /> } onClick={ onNotifyDialog }
      disabled={ disableButton }
      data-testid="btn-save-close-configuration"
      className={ getClassNameButton() }
    >
      <span>{t( 'labels.addProduct' )}</span>
    </Button> </>
    : <><Tooltip title={ t( 'tooltip.saveAndClose' ) }>
      <span>
        <IconButton
          onClick={ onNotifyDialog }
          disabled={ disableButton }
          className={ `action-panel-buttons ${getClassName( disableButton,'notify' )}` }
          data-testid="btn-save-close-configuration"
        >
          <SaveIcon />
        </IconButton>
      </span>
    </Tooltip> </>
  
}