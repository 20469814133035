import { configurationActions, productSettingsActions } from '../store';
import { getPackagePath, getProductId } from '../services';
import { EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import { getHeaders } from './HttpUtil';


export const ProductApi = {
  productCatalog: ( action: { token: string, page:number, limit:number, productSearch?: string,agCodes?:string[] } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.productSearch ? `${EUrlParams.ProductSearch}=` + action.productSearch + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/product/v1/catalog?${queryParams}`,
        {
          agCodes: action.agCodes ? action.agCodes : []
        },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: productSettingsActions.PRODUCTCATALOG,
          productCatalog: response.data,
          pageDetails:{page: action.page,totalRecords: total,limit: action.limit,agCodes:action.agCodes ? action.agCodes : []}
        }
      } ).catch( ( ) => null )
  },

  myConfigurations: ( action: { token: string, page:number, limit:number, productSearch?: string,channel?:string[] } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.productSearch ? `${EUrlParams.ProductSearch}=` + action.productSearch + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/user/v1/configurations/read?${queryParams}`,
        {
          applicationIds: action.channel ? action.channel.filter( x=>x !== 'Application' ) : []
        },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: productSettingsActions.READMYCONFIGURATIONS,
          myConfigurations: response.data === '' ? [] : response.data,
          pageDetails:{page: action.page,totalRecords: total,limit: action.limit,channel: action.channel ? action.channel : []}
        }
      } ).catch( ( ) => null )
  },

  getProductImages: ( action: { token: string, modelId: string } ) => {
    return AxiosClient
      .get( `/product/v1/images/read?packagePath=${getPackagePath( getProductId() )}&productModel=${action.modelId}`,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: configurationActions.PRODUCTIMAGES,
          productImages: response.data,
          modelId: action.modelId
        }
      } ).catch( ( ) => null )
  },

  productHierarchy: ( action : { token: string } )=>{
    return AxiosClient
      .get( '/product/v1/hierarchy',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      )
      .then( ( response ) => {
        return {
          type: productSettingsActions.PRODUCTHIERARCHY,
          productHierarchy: response.data
        }
      } ).catch( ( ) => null )
  },

  channelHierarchy: ( action : { token: string } )=>{
    return AxiosClient
      .get( '/user/v1/configurations/category/read',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      )
      .then( ( response ) => {
        return {
          type: productSettingsActions.CHANNELHIERARCHY,
          channelHierarchy: response.data
        }
      } ).catch( ( ) => null )
  }
}