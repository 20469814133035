import React from 'react';
import { canBeOptional, checkIsMarkedOptional, getFeatureId } from '../../services';
import { Box, Checkbox} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IOptionalControlProps,IConfigurationVariable } from '../../../types';
import { isFeatureVisible } from '../../services/ClaimsHelper';

export const OptionalControl = ( {variable, onOptionalChange,featureFamily,disabled,featureFlag}:IOptionalControlProps ) => {
  
  const {t} = useTranslation();

  // To identify feature belongs to which family concatinate familyid and feature id with . if variable type is multiselect/select
  const id = getFeatureId(variable.value,variable);

  function handleChange ( e:{target:{checked:boolean}} ) {
    onOptionalChange && onOptionalChange( id, e.target.checked,featureFamily ? featureFamily : variable as IConfigurationVariable ) 
  }
  
  if( !isFeatureVisible(featureFlag) ) {
    return null
  }
  
  const isVariableOptional = canBeOptional( variable.properties ? variable.properties : [] );
  if( !isVariableOptional ) {
    return <><Box className="hyphen"> - </Box></>
  }

  return <>
    <Box display={ { xs: 'inline', sm: 'none' } }>{t( 'labels.optional' )}:&nbsp;</Box>
    <Checkbox checked={ checkIsMarkedOptional( id,featureFamily ? featureFamily : variable as IConfigurationVariable ) } onChange={ handleChange }
      disabled={ disabled } className="paddingTopBottom"
    />
  </> 
}
