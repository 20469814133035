import { ConfigurationApi } from './ConfigurationApi';
import { PriceApi } from './PriceApi';
import { UserSettingsApi } from './UserSettingsApi';
import { SalesTextApi } from './SalesTextApi';
import { serviceExtension } from '../store/AppStore';
import { configurationActions,userSettingsActions,productSettingsActions,claimsDataActions,userPreferencesActions } from '../store';
import { ProductApi } from './ProductApi';
import { NotifyAPI } from './NotifyAPI';
//registration of API calls on specified dispatch actions


serviceExtension.add( configurationActions.CONFIGURE, ConfigurationApi.configure,false );
serviceExtension.add( configurationActions.SALESTEXT, SalesTextApi.salesText,false );
serviceExtension.add( configurationActions.GETVIEWLIST, ConfigurationApi.getViewList,true );
serviceExtension.add( configurationActions.LOADCONFIGURATION, ConfigurationApi.loadConfiguration,true );
serviceExtension.add( configurationActions.GETPMLANGUAGES, ConfigurationApi.getPMLanguages,true );
serviceExtension.add( userPreferencesActions.GET, UserSettingsApi.getUsersettings,false );
serviceExtension.add( userPreferencesActions.UPDATE, UserSettingsApi.updateUsersettings,false );
serviceExtension.add( configurationActions.PRICE, PriceApi.price,false );
serviceExtension.add( configurationActions.CURRENCYLIST, PriceApi.currency,false );
serviceExtension.add( configurationActions.CLEARCONFIGURATION, ConfigurationApi.clearConfiguration,false );
serviceExtension.add( configurationActions.PRICELISTMAP, PriceApi.priceListMap,false );
serviceExtension.add( productSettingsActions.PRODUCTCATALOG, ProductApi.productCatalog,false );
serviceExtension.add( configurationActions.CREATECONFIGURATION, ConfigurationApi.createConfiguration,true );
serviceExtension.add( configurationActions.NOTIFY, NotifyAPI.notify,false );
serviceExtension.add( productSettingsActions.READMYCONFIGURATIONS, ProductApi.myConfigurations,false );
serviceExtension.add( configurationActions.PRODUCTIMAGES, ProductApi.getProductImages,false );
serviceExtension.add( configurationActions.EXPORTBOMEXCEL, ConfigurationApi.exportConfigurationBomExcel,false );
serviceExtension.add( userSettingsActions.GETUSERDETAILS, UserSettingsApi.getUserDetails,false );
serviceExtension.add( productSettingsActions.PRODUCTHIERARCHY, ProductApi.productHierarchy,true );
serviceExtension.add( productSettingsActions.CHANNELHIERARCHY, ProductApi.channelHierarchy,false );
serviceExtension.add( claimsDataActions.GETCLAIMS, ConfigurationApi.getClaims, false );
serviceExtension.add( configurationActions.SETOPTIONALITEMS, ConfigurationApi.setOptionalItems, false );