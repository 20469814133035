import { getConflict, isSubmodelCountVariable, isValidConfigurableSection,createVariableAssignments } from '../../services';
import { IAssignment, IConfiguration, IConfigurationAction, IConfigureRequest, IConflictHierarchy, IContextData, IContextDataCountMap, IContextDataModel, IExtendedConfigureResponse, IExtendedConfigureResponseOptional, IFeatureOrFamily, ILastChange, ILoadConfiguration, ISection, IState, IVariableAssignment, IMaterialImages,IOrderingInstructionsPMP } from '../../../types';
import { Dispatch } from 'react';
import { SessionStore } from '../../services/SessionStore';
import { IdFormat, PriorityValue ,ESessionStore, EConfigurationProperty,ETabValue,UserAssignmentsScope,EActionType,EUrlParams, EVariableType } from '../../data/Constants';
import { appSettings } from '../../settings';
import { UrlHelper } from '../../services/UrlHelper';

export const configurationActions = {
  GETVIEWLIST: 'configureActions-GETVIEWLIST',
  SETVIEWID:'configureActions-SETVIEWID',
  LOADCONFIGURATION: 'configureActions-LOADCONFIGURATION',
  LOADEDCONFIGURATION: 'configureActions-LOADEDCONFIGURATION',
  CONFIGURE: 'configureActions-CONFIGURE',
  CONFIGURED: 'configureActions-CONFIGURED',
  CLEARCONFLICT: 'configureActions-CLEARCONFLICT',
  INVALIDVIEW: 'configureActions-INVALIDVIEW',
  VALIDVIEW: 'configureActions-VALIDVIEW',
  SALESTEXT: 'configurationAction-SALESTEXT',
  SETAUTHSTATE: 'configurationAction-SETAUTHSTATE',
  SETINFODIALOGVARIABLE: 'configurationAction-SETINFODIALOGVARIABLE',
  RESETINFODIALOGVARIABLE: 'configurationAction-RESETINFODIALOGVARIABLE',
  GETPMLANGUAGES: 'configurationAction-GETPMLANGUAGES',
  PRICE: 'configurationAction-PRICE',
  CURRENCYLIST: 'configurationAction-CURRENCYLIST',
  SETMODELSALESTEXT: 'configurationAction-SETMODELSALESTEXT',
  SETMODELPRICE: 'configurationAction-SETMODELPRICE',
  RESETMODELSALESTEXT: 'configurationAction-RESETMODELSALESTEXT',
  RESETMODELPRICE: 'configurationAction-RESETMODELPRICE',
  CLEARCONFIGURATION: 'configurationAction-CLEARCONFIGURATION',
  PRICELISTMAP: 'configurationAction-PRICELISTMAP',
  CLEAROPTIONALITEM: 'configurationAction-CLEAROPTIONALITEM',
  RESETSHORTSALESTEXT: 'configurationAction-RESETSHORTSALESTEXT',
  UPDATEPRODUCTSEARCHVALUE: 'configurationAction-UPDATEPRODUCTSEARCHVALUE',
  ONCHANGEPRODUCTSEARCHVALUE: 'configurationAction-ONCHANGEPRODUCTSEARCHVALUE',
  CREATECONFIGURATION: 'configurationAction-CREATECONFIGURATION',
  SETLOADERMESSAGE: 'configurationAction-SETLOADERMESSAGE',
  NOTIFY: 'configurationAction-NOTIFY',
  SETCONFIGURATIONAPI: 'configurationAction-SETCONFIGURATIONAPI',
  RESETCONFIGURATION: 'configurationAction-RESETCONFIGURATION',
  PRODUCTIMAGES: 'configurationAction-PRODUCTIMAGES',
  EXPORTBOMEXCEL: 'configurationAction-EXPORTBOMEXCEL',
  ONLOADCHECK: 'configurationAction-ONLOADCHECK',  
  UPDATEMYCONFIGDETAILS: 'configurationAction-UPDATEMYCONFIGDETAILS',
  CHANGELANDINGTAB:'configurationAction-CHANGELANDINGTAB',
  SETOPTIONALITEMS: 'configureActions-SETOPTIONALITEMS',
}

const initialState = {
  data: null,
  assignments: [],
  configurationId: null,
  languageCode: null,
  viewIds: null,
  lastChange: null,
  savedConfiguration: null,
  conflict: null,
  invalidView: null,
  isInvalid: null, //to check if configuration is invalid on first configure call
  errorCode: null,
  acceptedChanges: null,
  longSalesText: [],
  pmLanguages: null,
  authState: 'initial',
  infoDialogVariable: null,
  contextData: null,
  price: { prices: [] },
  currencyList: [],
  modelSalesTextData: new Map<string, boolean>(),
  modelPriceData: new Map<string, boolean>(),
  bundledFeatures: {},
  shortSalesText: {},
  productSearchValue:'',
  onChangeProductSearchValue:'',
  loaderMessage: null,
  configureApiCall: false,
  productImages: {},
  access:'',
  onLoadCheck: false,
  checkLandingTab:{isTabChanged:false,tabValue:ETabValue.Product},
  orderingInstructions:{}
} as IExtendedConfigureResponseOptional;

export const configurationDispatcher = ( dispatch: Dispatch<IConfigurationAction> ) => ( {
  getViewList: ( configurationId:string, token: string ) => dispatch( { type: configurationActions.GETVIEWLIST, configurationId, token} ),
  loadConfiguration: ( configurationId: string, languageCode: string, token: string, viewId?: string ) => dispatch( { type: configurationActions.LOADCONFIGURATION, configurationId, languageCode, token, viewId } ),
  configure: ( request: IConfigureRequest, token: string, language: string, lastChange: ILastChange | null = null ) => dispatch( { type: configurationActions.CONFIGURE, request, token, language, lastChange } ),
  clearConflict: () => dispatch( { type: configurationActions.CLEARCONFLICT } ),
  validViewId: () => dispatch( { type: configurationActions.VALIDVIEW } ),
  getSalesText: ( language: string, token: string, modelId: string, apiType: string, showError = false ) => dispatch( { type: configurationActions.SALESTEXT, language, token, modelId, apiType, showError } ),
  setAuthState: ( authState: string ) => dispatch( { type: configurationActions.SETAUTHSTATE, authState } ),
  setInfoDialogVariable: ( infoDialogVariable: IFeatureOrFamily ) => dispatch( { type: configurationActions.SETINFODIALOGVARIABLE, infoDialogVariable } ),
  resetInfoDialogVariable: () => dispatch( { type: configurationActions.RESETINFODIALOGVARIABLE } ),
  getPMLanguages: ( configurationId: string, token: string ) => dispatch( { type: configurationActions.GETPMLANGUAGES, configurationId, token } ),
  getPrice: ( token: string, countryCode: string, currencyCode: string, modelId: string,priceOnChange?: boolean ) => dispatch( { type: configurationActions.PRICE, token, countryCode, currencyCode, modelId,priceOnChange } ),
  getCurrencyList: ( token: string, countryCode: string ) => dispatch( { type: configurationActions.CURRENCYLIST, token, countryCode } ),
  setModelSalesText: ( modelId: string ) => dispatch( { type: configurationActions.SETMODELSALESTEXT, modelId } ),
  setModelPrice: ( modelId: string ) => dispatch( { type: configurationActions.SETMODELPRICE, modelId } ),
  resetModelSalesText: () => dispatch( { type: configurationActions.RESETMODELSALESTEXT } ),
  resetModelPrice: () => dispatch( { type: configurationActions.RESETMODELPRICE } ),
  clearConfiguration: ( configurationId: string, token: string, viewId: string ) => dispatch( { type: configurationActions.CLEARCONFIGURATION, configurationId, token, viewId } ),
  getPriceListMap: ( token: string ) => dispatch( { type: configurationActions.PRICELISTMAP, token } ),
  clearOptionalItem: () => dispatch( { type: configurationActions.CLEAROPTIONALITEM } ),
  resetShortSalesText: () => dispatch( { type: configurationActions.RESETSHORTSALESTEXT } ),
  createConfiguration: ( token: string, request: IConfigureRequest ) => dispatch( { type: configurationActions.CREATECONFIGURATION, token, request } ),
  setLoaderMessage: ( loaderMessage: string ) => dispatch( { type: configurationActions.SETLOADERMESSAGE, loaderMessage } ),
  notify: ( configurationId: string, token: string ) => dispatch( { type: configurationActions.NOTIFY, configurationId, token } ),
  setConfigureAPI: ( value: boolean ) => dispatch( { type: configurationActions.SETCONFIGURATIONAPI, value } ),
  resetConfiguration: () => dispatch( { type: configurationActions.RESETCONFIGURATION } ),
  getProductImages: ( token: string, modelId: string ) => dispatch( { type: configurationActions.PRODUCTIMAGES, token, modelId } ),
  exportConfigurationBomExcel: ( configurationId: string, token: string ) => dispatch( { type: configurationActions.EXPORTBOMEXCEL, configurationId, token } ),  
  updateProductSearchValue: ( productSearch ) => dispatch( { type: configurationActions.UPDATEPRODUCTSEARCHVALUE, productSearch } ),
  onChangeProductSearchValue: ( productSearch ) => dispatch( { type: configurationActions.ONCHANGEPRODUCTSEARCHVALUE, productSearch } ),
  changeLandingTab: ( value: boolean,tabValue:string ) => dispatch( { type: configurationActions.CHANGELANDINGTAB , value,tabValue } ),
  setOptionalItems: ( token:string,configurationId:string,optionalItems:string[]|null ) => dispatch( { type: configurationActions.SETOPTIONALITEMS , token,configurationId,optionalItems } )
} )

export const configurationReducer = ( state = initialState, action: IConfigurationAction = { type: '' } ) => {
  switch ( action.type ) {
    case configurationActions.SETVIEWID:
      SessionStore.set( ESessionStore.ViewId, action.data.length > 0 && action.data[0] )
      return {...state,viewIds:action.data}
    case configurationActions.LOADCONFIGURATION:
      return { ...state, configurationId: action.configurationId, languageCode: action.languageCode, isInvalid: null, acceptedChanges: null };
    case configurationActions.LOADEDCONFIGURATION: {
      return loadedConfiguration( action,state );
    }
    case configurationActions.CONFIGURE:
      return { ...state, assignments: action.request?.configureRequest?.line.variableAssignments, lastChange: action.lastChange || null };
    case configurationActions.CLEARCONFLICT:
      return { ...state, conflict: null };
    case configurationActions.INVALIDVIEW: //invalid configuration
      return { ...state, invalidView: true, errorCode: action['errorCode'] };//send error code 
    case configurationActions.VALIDVIEW:
      return { ...state, invalidView: false };// success code =null
    case configurationActions.CONFIGURED: {
      return configured( action, state )
    }
    case configurationActions.SALESTEXT: {
      return getSalesText( action, state );
    }
    case configurationActions.SETAUTHSTATE: {
      return { ...state, authState: action.authState };
    }
    case configurationActions.SETINFODIALOGVARIABLE: {
      return { ...state, infoDialogVariable: action.infoDialogVariable };
    }
    case configurationActions.RESETINFODIALOGVARIABLE: {
      return { ...state, infoDialogVariable: null };
    }
    case configurationActions.GETPMLANGUAGES: {
      return { ...state, pmLanguages: action?.pmLanguages ? action?.pmLanguages[0].languages : []};
    }
    case configurationActions.PRICE: {
      return getPriceData( action, state );
    }
    case configurationActions.CURRENCYLIST: {
      return { ...state, currencyList: action?.currency }
    }
    case configurationActions.SETMODELSALESTEXT: {
      const modelSalesTextData = structuredClone ( state.modelSalesTextData );
      if ( action.modelId ) {
        modelSalesTextData.set( action.modelId, true );
      }
      return { ...state, modelSalesTextData: modelSalesTextData };
    }
    case configurationActions.SETMODELPRICE: {
      const modelPriceData = structuredClone ( state.modelPriceData );
      if ( action.modelId ) {
        modelPriceData.set( action.modelId, true );
      }
      return { ...state, modelPriceData: modelPriceData };
    }
    case configurationActions.RESETMODELSALESTEXT: {
      return { ...state, modelSalesTextData: new Map<string, boolean>(), salesText: {} };
    }
    case configurationActions.RESETMODELPRICE: {
      return { ...state, modelPriceData: new Map<string, boolean>(), price: {} };
    }
    case configurationActions.CLEAROPTIONALITEM: {
      const savedConfiguration = {...state.savedConfiguration};
      if ( savedConfiguration ) {
        savedConfiguration.optionalItems = new Map<string, boolean>()
      }
      return { ...state, savedConfiguration: savedConfiguration };
    }
    case configurationActions.RESETSHORTSALESTEXT: {
      return { ...state, shortSalesText: {} };
    }
    case configurationActions.SETLOADERMESSAGE: {
      return { ...state, loaderMessage: action.loaderMessage };
    }
    case configurationActions.SETCONFIGURATIONAPI: {
      return { ...state, configureApiCall: action.value }
    }
    case configurationActions.RESETCONFIGURATION: {
      return getInitialState();
    }
    case configurationActions.PRODUCTIMAGES: {        
      return setProductImages( action, state );
    }
    case configurationActions.ONLOADCHECK: {
      return { ...state, onLoadCheck: action.key };
    }
    case configurationActions.UPDATEPRODUCTSEARCHVALUE:{
      return { ...state, productSearchValue: action.productSearch }
    }
    case configurationActions.ONCHANGEPRODUCTSEARCHVALUE:{
      return { ...state, onChangeProductSearchValue: action.productSearch }
    }
    case configurationActions.CHANGELANDINGTAB:{
      return { ...state, checkLandingTab:{isTabChanged:action.value,tabValue:action.tabValue} } 
    }
    case configurationActions.SETOPTIONALITEMS:{
      return { ...state } 
    }
    default:
      return state;
  }
};

const createOptionalItems = ( data: ILoadConfiguration ) => {
  const optionalItems = new Map<string, boolean>();
  if ( data.optionalItems ) {
    for ( const key of Array.from( data.optionalItems?.keys() ) ) {
      optionalItems.set( data.optionalItems[key], true );
    }
  }
  data.optionalItems = optionalItems;
}

function getInitialState() {
  return {...JSON.parse( JSON.stringify( initialState ) ),modelSalesTextData: new Map<string, boolean>(),
    modelPriceData: new Map<string, boolean>()};
}
const loadedConfiguration = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional )=>{
  if ( action.data ) {
    createOptionalItems( action.data )
  }
  const variableAssignments = createVariableAssignments( action.data.userAssignments )
  const newState = { ...state, savedConfiguration: action.data, contextData: getContextData( action.data ), assignments: variableAssignments,access:action.access };
  const OrderingInstructions = {};
  createORIValuesforPMP( action.configuration ? action.configuration : action.data.configureResponse,OrderingInstructions )
  return { ...state, ...configured( action, newState ),orderingInstructions:OrderingInstructions };
}
const configured = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  action.configuration = action.configuration ? action.configuration : action.data.configureResponse;
  if ( !action.configuration ) {
    return state;
  }
  const assignmentsToRemove = action.configuration.removedAssignments?.variableAssignments;
  let assignments = state.assignments ? [...state.assignments] : [];
  if( action.apiType === EActionType.ClearAll ) {
    assignments = assignments.filter( assignedmentVal => UserAssignmentsScope.find( userVal => userVal === assignedmentVal.variableId ) ) //Update UserAssignmentsScope in Constant file if new static id adds up
  }
  const conflict = state.isInvalid !== false ? null : getConflict( { ...state, data: { ...action.configuration } } as IExtendedConfigureResponse );
  const shortSalesText = state.shortSalesText;

  if ( assignmentsToRemove ) {
    assignments = removeAssignments( assignmentsToRemove, shortSalesText, assignments );
  }
  const args = action.configuration.arguments?.Configuration || {};
  const submodels = Object.keys( args ).filter( e => {
    return e.endsWith( IdFormat.Suffix.SubmodelId ) && args[e] >= 1
  } );
  submodels.forEach( m => {
    const ass = assignments.find( a => a.variableId === m );
    if ( !ass ) {
      assignments.push(
        { assignmentType: 'Singleton', variableId: m, value: args[m].toString(), exclude: false, priority: PriorityValue.High }
      )
    } else if ( Number( ass.value ) >= 1 ) {
      ass['priority'] = PriorityValue.High;
    }
  } )
  sanitizeConfiguration( action.configuration, state.contextData );
  const countVariables: IContextDataCountMap = {};
  state.contextData?.countVariables && Object.keys( state.contextData?.countVariables ).forEach( ( k ) => {
    countVariables[k] = null;
  } )
  const bundledFeatures: { [key: string]: boolean } = {};
  mapCountAndBundleFeatures( action.configuration.sections, countVariables, bundledFeatures );
  //remove once real properties are available
  // fixSubModelViews( action.configuration );
  return checkInvalid( { ...state, contextData: { ...state.contextData, countVariables: { ...countVariables } }, bundledFeatures: bundledFeatures }, action, conflict, assignments, assignmentsToRemove )
}
export const configurationState = ( state: IState ) => ( { configuration: state.configuration } );

const sanitizeConfiguration = ( configuration: IConfiguration, contextData: IContextData | null ) => {
  configuration.sections = configuration.sections?.filter( ( s ) => sanitizeSection( contextData, s ) );
}
// function to create orderingInstructions data for PM & SubModel
const createORIValuesforPMP = ( configuration: IConfiguration,OrderingInstructions ) => {  
  configuration.sections?.forEach( ( s ) => checkORIOnHiddenSec( s,OrderingInstructions,configuration.product ) );
}
// check ORI property on Hidden Section
const checkORIOnHiddenSec = ( section: ISection,OrderingInstructions:IOrderingInstructionsPMP,Product:{id:string} ) => {
  if( section.sections.length > 0 ) {
    section.sections?.forEach( ( s ) => {
      checkORIOnHiddenSec( s,OrderingInstructions,Product ) 
    } )
  }
  if( !section.id || section.id?.endsWith( '_HIDDEN_' ) ) {
    const pmpVariable = section.variables.find( variable => variable.id.includes( 'PMP' ) );
    const pmpVariableORI = pmpVariable && pmpVariable.properties.find( property => property.id === EConfigurationProperty.OrderingInstructions )
    if( pmpVariableORI ) { 
      // adding ORI data for SubModels     
      if( pmpVariable.id.split( '.' ).length > 1 ) {
        const pmpVariableKey = pmpVariable.id.split( '.' );
        let pmpVariableId = pmpVariableKey[pmpVariableKey.length - 2];
        pmpVariableId = pmpVariableId.substring( pmpVariableId.indexOf( '_' ) + 1, pmpVariableId.length )
        OrderingInstructions[pmpVariableId] = {value:pmpVariableORI.value}
      }else {
        // adding ORI data for main PM
        OrderingInstructions[Product.id] = {value:pmpVariableORI.value}
      }
    }
  }
  
}

const sanitizeSection = ( contextData: IContextData | null, section: ISection ) => {
  section.sections = section.sections?.filter( ( s ) => sanitizeSection( contextData, s ) );
  return isValidConfigurableSection( contextData, section );
}

const checkInvalid = ( state: IExtendedConfigureResponseOptional, action: IConfigurationAction, conflict: { assignmentsToRemove: IConflictHierarchy; assignmentsBefore: IAssignment[]; } | null, assignments: IAssignment[], assignmentsToRemove: string | any[] | undefined ) => {
  if ( state.isInvalid === null ) {
    if ( assignmentsToRemove?.length ) {
      return { ...state, conflict, data: action.configuration, assignments, isInvalid: true, acceptedChanges: false };
    } else {
      return { ...state, conflict, data: action.configuration, assignments, isInvalid: false, acceptedChanges: false };
    }
  } else {
    if ( state.isInvalid ) {
      //If user accepts proposed changes
      return { ...state, conflict: null, data: action.configuration, assignments, isInvalid: false, acceptedChanges: true }
    }
    return { ...state, conflict, data: action.configuration, assignments };
  }
}

/**
 * Generates and returns the context data from model context
 * @param {ILoadConfiguration} data The data received from read api call
 * @returns {IContextData} context data which maps model id to model context
 */
function getContextData( data?: ILoadConfiguration ) {
  if ( !data ) {
    return { models: null }
  }
  const modelContext = data.modelContext;
  const { rootModel, subModels } = modelContext
  const models: { [key: string]: IContextDataModel } = { [rootModel.id]: { ...rootModel, isRoot: true } };
  if ( rootModel.id ) {
    SessionStore.set( ESessionStore.ProductId, rootModel.id );
  }
  const countVariables: IContextDataCountMap = {};
  subModels.forEach( ( sm ) => {
    models[sm.id] = sm
    if ( sm.subModelVariableIds?.length ) {
      sm.subModelVariableIds?.forEach( ( v ) => {
        countVariables[v] = null;
      } )
    }
  } );

  return { models, countVariables }
}

/**
 * Returns an object that maps the count variables from configure response to the count variables ids in context data
 * @param {ISection[]} sections the sections received in configure response
 * @param {IContextDataCountMap} countVariables the object to store the map
 * @param {bundledFeatures} bundledFeatures the object to store the map of bundle features
 * @returns {void}
 */
function mapCountAndBundleFeatures( sections: ISection[], countVariables: IContextDataCountMap, bundledFeatures: { [key: string]: boolean } ) {
  sections.forEach( s => {
    s.variables.forEach( v => {
      v.values.filter( val => val.state.isAssigned )?.forEach( val => {
        const vl = val.properties?.find( vp => vp.id === EConfigurationProperty.BundleContent );
        vl?.value?.toString().split( ',' ).forEach( vid => bundledFeatures[vid] = true );
      }
      )
      if ( v.id in countVariables || isSubmodelCountVariable( v ) ) {
        countVariables[v.id] = v;
      }
    } )
    s.sections?.length && mapCountAndBundleFeatures( s.sections, countVariables, bundledFeatures );
  } )
}

// To get shortSalesText data
function getShortSalesTextData( state: IExtendedConfigureResponseOptional, action: IConfigurationAction ) {
  const shortSalesText = state.shortSalesText || {};
  const shortSalesTextData: { [key: string]: { id: string, text: string } } = {
  }
  action?.salesText && action.salesText.salesTexts?.forEach( ( text: any ) => {
    if ( text.id && !( text.id in shortSalesText ) ) {
      shortSalesTextData[text.id] = text;
    }
  } )
  const productModelId = 'Model_' + action.modelId;
  if ( action.modelId ) {
    shortSalesTextData[productModelId] = { id: action.modelId, text: '' }
  }
  return { ...state, shortSalesText: { ...shortSalesText, ...shortSalesTextData } }
}

const getSalesText = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  if ( action.apiType === 'short' ) {
    return getShortSalesTextData( state, action );
  } else {
    const longSalesText = state.longSalesText || [];
    const data = action.salesText?.salesTexts || [];
    const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );  
    const subModelCollection = modelPath ? modelPath.split( '.' ) : [] ;
    //concat SalesText of existing data from state & subModel data from api
    if( subModelCollection.length > 0 ) {
      return { ...state, longSalesText: [...longSalesText, ...data] }
    } else {
      return { ...state, longSalesText: [ ...data] } // override salesText data on lang change if not submodel
    }    
  }
}

const getPriceData = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  const price = state.price || {};
  const priceData: { [key: string]: { materialCode: string, price: number } } = {
  }
  action?.price && action.price.prices.forEach( ( text: any ) => {
    if ( text.materialCode && !( text.materialCode in price ) ) {
      priceData[text.materialCode] = text;
    }
  } )
  return { ...state, price: { ...price, ...priceData } }
}

const removeAssignments = ( assignmentsToRemove: IVariableAssignment[], shortSalesText: { [key: string]: any } | undefined, assignments: IAssignment[] ) => {
  const validAssignments = [...assignments];
  for ( const assignmentToRemove of assignmentsToRemove ) {
    assignmentToRemove.variable.shortSalesText = appSettings.UseShortSalesText && shortSalesText && shortSalesText[assignmentToRemove.variable.id] ?
      shortSalesText[assignmentToRemove.variable.id].text : null;
    assignmentToRemove.value.shortSalesText = appSettings.UseShortSalesText && shortSalesText && assignmentToRemove.value.value && shortSalesText[assignmentToRemove.value.value.toString()] ?
      shortSalesText[assignmentToRemove.value.value.toString()].text : null;
    let index = -1;
    //Compare the feature value name for Numeric features and rest all type features compare the value
    if( assignmentToRemove.variable.valueType === EVariableType.Number ) {
      index = validAssignments.findIndex( a => a.variableId === assignmentToRemove.variable.id && a.value === assignmentToRemove.value.name );
    }else{
      index = validAssignments.findIndex( a => a.variableId === assignmentToRemove.variable.id && a.value === assignmentToRemove.value.value );
    }
    if( index >= 0 ) {
      validAssignments.splice( index, 1 )
    }
  }
  return validAssignments;
}

const setProductImages = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  action.productImages = action.productImages && typeof action.productImages === 'string' ? JSON.parse( action.productImages ) : action.productImages;
  const productImages = state.productImages || {};
  const updatedImageData: { [key: string]: { materialCode: string, materialImages: IMaterialImages[] } } = {
  }
  action?.productImages && action.productImages.productImages?.forEach( ( data: any ) => {
    if ( data.materialCode && !( data.materialCode in productImages ) ) {
      updatedImageData[data.materialCode] = data;
    }
  } )
  const productModelId = 'Model_' + action.modelId;
  if ( action.modelId ) {
    updatedImageData[productModelId] = { materialCode: action.modelId, materialImages: [] }
  }
  return { ...state, productImages: { ...productImages, ...updatedImageData } }
}