import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { userSettingsState } from '../store';
import { ISalesTextProps } from '../../types';
import { getVariableLongSalesText } from '../services';
import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';
/**
   * Renders the short sales text popup.
   * @param {ISalesTextProps} props the properties for the short sales text component
   * @returns {JSX.Element} the short sales text popup component
   */
export const $SalesText = ( { variable} : ISalesTextProps ) => {
  const {t} = useTranslation();
  const salesText = getVariableLongSalesText( variable );
  let longSaleText = null
  try {
    longSaleText = salesText ? JSON.parse( salesText ) : salesText;
  } catch {
    longSaleText = salesText;
  }
  return !longSaleText ? <p className="noTextMessage">{t( 'salesText.noSalesText' )}</p> : 
    <div className="salesText">  {parse( DOMPurify.sanitize( longSaleText ) )} </div>
}
export const $SalesTextWrapper = ( { variable,userSettings}: ISalesTextProps ) => {
  if( !( variable && userSettings ) ) {
    return null;
  }
  return <$SalesText variable = { variable } userSettings = { userSettings } />
}
export const SalesText = connect( userSettingsState )( $SalesTextWrapper ) 


