import { EBrowserType,EDeviceType } from '../data/Constants';
import { UAParser } from 'ua-parser-js';
const parser = new UAParser();
const parserResults = parser.getResult();
export function isChrome () {
  return parserResults.browser.name === EBrowserType.Chrome&& parseFloat(parserResults.browser.version) > 100;
}

export function isEdge () {
  return parserResults.browser.name === EBrowserType.Edge&& parseFloat(parserResults.browser.version) > 100;
}

export function isSafari () {
  return parserResults.browser.name?.includes( EBrowserType.Safari )&& parseFloat(parserResults.browser.version) > 14;
}

export function isMobile () {
  return parserResults.device.type === EDeviceType.Mobile || parserResults.device.type === EDeviceType.Tablet ;
}
