import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { configurationDispatcher, configurationState } from '../store';

/**
 * Renders an error message for unauthorized users
 * @param {IUnauthenticatedProps} props the props required by the component
 * @returns {JSX.Element} the error message component
 */
export const $Unauthorized = ( ) => {
  const {t} = useTranslation()
  return (
    <h4>
      {t( 'errorMessages.general.403' )}
    </h4> 
  );
};

export const Unauthorized = connect( configurationState,configurationDispatcher )( $Unauthorized );