import { Dispatch } from 'react';
import { IProductAction, IState } from '../../../types';

export const productSettingsActions = {  
  PRODUCTCATALOG: 'productSettingsActions.PRODUCTCATALOG',
  UPDATEPRODUCTCATALOGDETAILS: 'productSettingsActions.UPDATEPRODUCTCATALOGDETAILS',
  READMYCONFIGURATIONS: 'productSettingsActions.READMYCONFIGURATIONS',
  UPDATEMYCONFIGDETAILS: 'productSettingsActions.UPDATEMYCONFIGDETAILS',
  PRODUCTHIERARCHY: 'productSettingsActions.PRODUCTHIERARCHY',
  CHANNELHIERARCHY: 'productSettingsActions.CHANNELHIERARCHY',
  RESETMYCONFIGURATIONS: 'productSettingsActions.RESETMYCONFIGURATIONS',
  ISSEARCHCONTENT: 'productSettingsActions.ISSEARCHCONTENT'
}

const defaultProductSettings = {
  productCatalog: {},
  productCatalogDetails: {page:1,limit:15,totalRecords:1,agCodes:[]},
  myConfigurations: {},
  myConfigPageDetails: {page:1,limit:15,totalRecords:1,channel:[]},
  productHierarchy: [],
  channelHierarchy: [],
  isSearhContent: false
}

export const productSettingsDispatcher = ( dispatch: Dispatch<IProductAction> ) => ( {
  getProductCatalog: ( token: string, page:number, limit:number, productSearch?: string,agCodes?: string[] ) => dispatch( { type: productSettingsActions.PRODUCTCATALOG, token, page, limit, productSearch,agCodes } ),
  updateProductCatalogDetails: ( page,limit,totalRecords,selectedAgCodes ) => dispatch( { type: productSettingsActions.UPDATEPRODUCTCATALOGDETAILS, page,limit,totalRecords,selectedAgCodes } ),
  getProductHierarchy: ( token: string ) => dispatch( { type: productSettingsActions.PRODUCTHIERARCHY, token } ),
  getChannelHierarchy: ( token: string ) => dispatch( { type: productSettingsActions.CHANNELHIERARCHY, token } ),  
  getMyConfigurations: ( token: string, page:number, limit:number, productSearch?: string,channel?: string[] ) => dispatch( { type: productSettingsActions.READMYCONFIGURATIONS, token, page, limit, productSearch, channel} ),
  updateMyConfigPageDetails: ( page,limit,totalRecords,channel ) => dispatch( { type: productSettingsActions.UPDATEMYCONFIGDETAILS, page,limit,totalRecords,channel } ),
  resetMyConfigurations : () => dispatch( { type: productSettingsActions.RESETMYCONFIGURATIONS } ),
  checkIsSearchContent : ( flag:boolean ) => dispatch( { type: productSettingsActions.ISSEARCHCONTENT,flag } )
} )

const productCatalogData = ( action,state )=>{
  let catalog = {};
  if( action?.productCatalog && action?.pageDetails?.page === 1 ) {        
    catalog = {[action.pageDetails.page]:action.productCatalog};
  } else if( action?.productCatalog ) {
    catalog = {...state.productCatalog};
    if( Object.keys( catalog ).length > 4 ) {
      delete catalog[Object.keys( catalog )[1]]; //To remove the second property when there are 5 entries
    }
    catalog = {...catalog, [action.pageDetails.page] : action.productCatalog};
  }
  return { ...state, productCatalog: action?.productCatalog ? catalog : {...state.productCatalog}, productCatalogDetails: action.pageDetails ? action.pageDetails : {page:1, limit:15,totalRecords:1,agCodes:[]} }
}

const myConfigurationData = ( action,state )=>{
  let configurations = {};
  if( action?.myConfigurations && action?.pageDetails?.page === 1 ) {        
    configurations = {[action.pageDetails.page]:action?.myConfigurations};
  } else if( action?.myConfigurations ) {
    configurations = {...state.myConfigurations};
    if( Object.keys( configurations ).length > 4 ) {
      delete configurations[Object.keys( configurations )[1]]; //To remove the second property when there are 5 entries
    }
    configurations = {...configurations, [action.pageDetails.page]:action?.myConfigurations};
  }
  return {...state, myConfigurations: action?.myConfigurations ? configurations : {...state.myConfigurations}, myConfigPageDetails: action.pageDetails ? action.pageDetails : {page:1, limit:15,totalRecords:1,channel:[]}};
}

export const productSettingsReducer = ( state = defaultProductSettings, action: IProductAction = { type: '' } ) => {  
  switch ( action.type ) {    
    case productSettingsActions.PRODUCTCATALOG:{
      return productCatalogData( action,state );
    }
    case productSettingsActions.UPDATEPRODUCTCATALOGDETAILS:{
      return { ...state, productCatalogDetails: {page:action.page, limit:action.limit,totalRecords:action.totalRecords,agCodes:action.agCodes} }
    }
    case productSettingsActions.READMYCONFIGURATIONS:{
      return myConfigurationData( action,state );
    }
    case productSettingsActions.UPDATEMYCONFIGDETAILS:{
      return { ...state, myConfigPageDetails: {page:action.page, limit:action.limit,totalRecords:action.totalRecords,channel:action.channel} }
    }
    case productSettingsActions.PRODUCTHIERARCHY: {
      return { ...state, productHierarchy: action.productHierarchy ? action.productHierarchy : [] }
    }
    case productSettingsActions.CHANNELHIERARCHY: {
      return { ...state, channelHierarchy: action.channelHierarchy ? [action.channelHierarchy] : [] }
    }
    case productSettingsActions.RESETMYCONFIGURATIONS: {
      return { ...state, myConfigurations:[],channelHierarchy:[],myConfigPageDetails: {page:1,limit:15,totalRecords:1,channel:[]} }
    }
    case productSettingsActions.ISSEARCHCONTENT: {
      return { ...state, isSearhContent:action.flag }
    }
    default:
      return state;
  }
};

export const productSettingsState = ( state: IState ) => ( { productSettings: state.productSettings } );