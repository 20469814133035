import { AxiosError } from 'axios';
import { configurationActions,claimsDataActions } from '../store';
import { getPackagePath } from '../services';
import { IConfigureRequest } from '../../types';
import { EActionType } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import { getHeaders } from './HttpUtil';


export const ConfigurationApi = {
  getViewList : ( action: { configurationId: string, token: string} ) =>{
    return AxiosClient
      .post( '/configurator/v1/configuration/views/read',
        { configurationId: action.configurationId },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response )=> {
        return {
          type: configurationActions.SETVIEWID,
          data: response.data
        } 
      } )
      .catch( ( err: {response:{data:{errorMessage:string},status:number},message:string,code:number} ) => {
        return {
          type: 'error-SET',
          response: err.response,
          error: {
            code: err.response?.status || err.code,
            message: err.response?.data ? err.response.data.Message : err.message,
            page: 'noViews'
          },
          message: err.message,
        };
      } )
  },

  loadConfiguration: ( action: { configurationId: string, languageCode: string, token: string, viewId: string } ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/read',
        { configurationId: action.configurationId, viewId: action.viewId },
        { headers: getHeaders( action.token, action.languageCode ) }
      )
      .then( ( response ) => ( {
        type: configurationActions.LOADEDCONFIGURATION,
        data: response.data,
        access: response.headers.access
      } ) ).catch( ( err: AxiosError ) => {
        if ( err.response ) {
          // To Dispatch Invalid View action on Failure response to Configure API
          return {
            type: 'error-SET',
            error: {
              code: err.response.status,
              data: err.response.data,
              page: 'configure'
            },
            viewId: err.response.data,
          }
        } else {
          return null;
        }
      } ),

  configure: ( action: { request: IConfigureRequest, token: string, language: string } ) => {
    return AxiosClient
      .post(
        `/configurator/v1/configuration/configure?packagePath=${getPackagePath( action.request.configureRequest?.line.productId )}`,
        action.request,
        { headers: getHeaders( action.token, action.language ) }
      )
      .then( ( response ) => ( {
        type: configurationActions.CONFIGURED,
        configuration: response.data
      } ) ).catch( ( err: AxiosError ) => {
        if ( err.response ) {
          // To Dispatch Invalid View action on Failure response to Configure API
          return {
            type: 'error-SET',
            error: {
              code: err.response.status,
              message: err.response.data,
              page: 'configure'
            },
            viewId: err.response.data,
          }
        } else {
          return null;
        }
      } )
  },

  getPMLanguages: ( action: { configurationId: string, token: string } ) => {
    return AxiosClient
      .get(
        `/product/v1/languages?configurationId=${action.configurationId}`,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: configurationActions.GETPMLANGUAGES,
          pmLanguages: response.data
        }
      } ).catch( () => {
        return null
      } )
  },

  clearConfiguration: ( action: { configurationId: string, token: string, viewId: string } ) => {
    return AxiosClient
      .post( '/configurator/v1/configuration/clear',
        {
          configurationId: action.configurationId,
          viewID: action.viewId
        },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: configurationActions.CONFIGURED,
          configuration: response.data,
          apiType: EActionType.ClearAll
        }
      } ).catch( ( err ) => {
        return {
          type: 'error-SET',
          response: err.response,
          error: {
            code: err.response.status,
            message: err.response.data ? err.response.data.Message : err.message,
          },
          message: err.message,
        };
      } )
  },

  createConfiguration: ( action: { token: string, request: IConfigureRequest } ) => {
    return AxiosClient
      .post(
        '/punchout/v1/configuration/create',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      ).then( ( response ) => {
        return {
          type: configurationActions.CREATECONFIGURATION,
          configurationId: response.data
        }
      } ).catch( ( err ) => {
        return {
          type: 'error-SET',
          error: {
            code: err.response.status,
            message: err.response.data,
            page: 'create-configuration',
            data: action.request,
          }
        };
      } )
  },

  exportConfigurationBomExcel: ( action: { configurationId: string | null, token: string, isMultipleSheet: boolean } ) => {
    return AxiosClient
      .post( '/report/configuration/bom/excel',
        { configurationId: action.configurationId, isMultipleSheet: action.isMultipleSheet },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return response;
      } ).catch( ( err ) => {
        return { type: 'error-SET', error: { code: err.response.status, message: err.response.data?.Message, } }
      } )
  },

  getClaims: ( action: { token: string } ) =>{
    return AxiosClient
      .get( '/configurator/v1/claims/read?applicationName=cflm',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: claimsDataActions.SETCLAIMS,
          claims: response.data
        }
      } ).catch( ( err ) => {
        return {
          type: 'error-SET',
          error: {
            code: err.response.status,
            message: err.response.data,
            page: 'claims', //Update this statement
          }
        };
      } )
  },
  
  setOptionalItems: ( action: { token:string,configurationId:string,optionalItems:string[]|null } ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/optional/set',
        {configurationId: action.configurationId, optionalItems: action.optionalItems} ,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      )
      .then( ( response ) => {
        return response;
      } ).catch( ( err ) => {
        return {
          type: 'error-SET',
          error: {
            code: err.response.status,
            message: err.response.data?.Message
          }
        }
      } ),

};
