import { Dispatch } from 'react';
import { DefaultUserSettings } from '../../data/Constants';
import { SessionStore } from '../../services/SessionStore';
import { IUserSettingsAction, IState, IUserSettings } from '../../../types';

export const userSettingsActions = {
  SET: 'userSettings-SET',
  RESET: 'userSettings-RESET',
  GET: 'userSettings-GET',
  UPDATE:'userSettings-UPDATE',
  GETUSERDETAILS:'userSettingsActions.GETUSERDETAILS',
  SETUSERDETAILS:'userSettingsActions.SETUSERDETAILS'
}

const sessionStoreKey = 'user-settings';
const defaultUserSettings = {...DefaultUserSettings, details:{}}

const getDefaultSettings = () => {
  const storedSettings = SessionStore.get( sessionStoreKey );
  let userSettings;

  if( !storedSettings ) {
    userSettings = defaultUserSettings;
  } else {
    for( const key in defaultUserSettings ) {
      if( !( key in storedSettings ) ) {
        //set default settings if stored settings are missing data
        userSettings = defaultUserSettings;
        break;
      }
    }

    userSettings = userSettings || storedSettings;
  }  

  return userSettings ;
}

export const userSettingsDispatcher = ( dispatch: Dispatch<IUserSettingsAction> ) => ( {
  setUserSettings: ( userSettings: IUserSettings ) => dispatch( { type: userSettingsActions.SET, userSettings } ),
  resetUserSettings: () => dispatch( { type: userSettingsActions.RESET } ),
  getUsersettings:( token: string )=>dispatch( { type: userSettingsActions.GET,token } ),
  updateUsersettings:( token: string,request:any )=>dispatch( { type: userSettingsActions.UPDATE,token,request } ),
  
  getUserDetails:( token: string )=>dispatch( { type: userSettingsActions.GETUSERDETAILS,token } )
} )

export const userSettingsReducer = ( state = { ...getDefaultSettings()} , action: IUserSettingsAction = {
  type: '',
  apiType: ''
} ) => {
  let newState = {...state};
  switch ( action.type ) {
    case userSettingsActions.SET:
      newState = { ...state, ...action.userSettings };
      return newState;
    case userSettingsActions.RESET:
      newState = { ...state, ...defaultUserSettings };
      return newState;
    case userSettingsActions.GET:
      newState = { ...state, ...action.data };
      return newState;
    case userSettingsActions.UPDATE:
      return { ...state, ...action.request };

    case userSettingsActions.SETUSERDETAILS:{
      return {...state, details: action?.data};
    }
    default:
      return state;
  }
};

export const userSettingsState = ( state: IState ) => ( { userSettings: state.userSettings } );