import React, { useEffect, useState } from 'react';
import { IStringControlProps } from '../../../types';
import { Box, FormHelperText, Grid, TextField} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gridLayout } from '../SectionLayout';
import { OptionalControl,Price} from '..';
import { EConfigurationProperty } from '../../data/Constants';

/**
 * Renders an input box for strings. 
 * Used for variables which allow the user to set a custom string
 * @param {IStringControlProps} props the properties for the string input component
 * @returns {JSX.Element} the string input component
 */
export const StringControl = ( { featureFlags, label, value, onChange, properties, disabled, variable, onOptionalChange } : IStringControlProps ) => {
  const [internalValue, setInternalValue] = useState( value );
  const [errorMessage, setErrorMessage] = React.useState<string[]>( [] );
  const {t} = useTranslation();
  const maxValidation = properties?.find( p => p.id === EConfigurationProperty.MaxLength );
  const maxValue = Number ( maxValidation?.value );
  const placeholderMsg = t( 'errorMessages.maxLength', {length : maxValue} );

  useEffect( ()=>{
    setInternalValue( value )
  },[value] )

  //Validate string to check if it exceeds the Max. length
  const validateUserString = ( userInput:string )=>{
    if( userInput.length > maxValue ) {
      const errors = [];
      errors.push( t( 'errorMessages.maxLength',{length: maxValue} ) );
      setErrorMessage( errors );
    } else{
      setErrorMessage( [] );
    }
  }

  const onUpdate = ( e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) =>{
    validateUserString( e.target.value );
    setInternalValue( e.target.value.substring( 0, maxValue ) );
  }

  // To call Congifure onBlur of the FormField
  const onEventChange = () => {
    //Clear error messages on Configure call since it has acceptable length
    setErrorMessage( [] );
    // to prevent Configure
    if( internalValue !== value ) {
      onChange && onChange( internalValue );
    }
  }

  return<Grid container item xs={ gridLayout.fullWidth } alignItems="center">
    <Grid item xs={ gridLayout.xs.input } sm={ gridLayout.sm.input } justifyContent="left" alignItems="center">
      <TextField className="w-25ch"
        key={ label }
        color="primary" 
        type="text" 
        value={ internalValue.substring( 0,maxValue ) }
        onChange={ ( e )=>onUpdate( e ) } onBlur={ ( )=>onEventChange() }
        disabled={ disabled }
        data-testid="string-control"
        placeholder = { placeholderMsg }
      />
    </Grid>
    <Grid container item xs={ gridLayout.xs.infoIcon } sm={ gridLayout.sm.infoIcon } alignItems="center" justifyContent="center"/>
    <Grid container item xs={ gridLayout.xs.optional } sm={ gridLayout.sm.optional } alignItems="center" justifyContent="center">
      <OptionalControl variable={ variable } onOptionalChange={ onOptionalChange } featureFlag = { featureFlags.Optional }/>
    </Grid>
    <Grid container item xs={ gridLayout.xs.price } sm={ gridLayout.sm.price } alignItems="right" justifyContent="right">
      <Box display={ {xs:'inline',sm:'none'} } textAlign="center">{t( 'labels.listPrice' )}:&nbsp;</Box>
      <Price price={ variable.price } isBundled={ variable.isBundled }/>
    </Grid>
    <FormHelperText className="validation-error">
      { errorMessage.map( ( error:string,idx:number )=><span key ={ idx.toString() }>{error}</span> ) }
    </FormHelperText>
  </Grid>
}