import { userPreferencesActions,userSettingsActions } from '../store';
import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import { getHeaders } from './HttpUtil';


function handleError( err ) {
  return {
    type: 'error-SET',
    error: {
      code: err.response.status,
      message: err.response.data?.Message,
    }
  };
}

export const UserSettingsApi = {
  getUsersettings: ( action: { token: string } ) => {
    return AxiosClient
      .get( '/user/v1/preferences/read',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: userPreferencesActions.GET,
          data: response.data
        }
      } ).catch( () => null )
  },

  updateUsersettings: ( action: { token: string, request: any } ) => {
    return AxiosClient
      .post( '/user/v1/preferences/set',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: userPreferencesActions.UPDATE,
          data: response.data
        }
      } ).catch( () => null )
  },

  getUserDetails: ( action: { token: string } ) => {
    return AxiosClient
      .get( '/user/v1/details',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          type: userSettingsActions.SETUSERDETAILS,
          data: response.data
        }
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}