import { IConfigurationVariable, IValueType } from '../../types';
import moment, { Moment } from 'moment';

type IRangeType = string | number | undefined;
type IExtendedValueType = IValueType | Moment;


export const getNumericValidationInfo = ( variable: IConfigurationVariable, value: number ) => {
  const isEqual = ( a: IExtendedValueType, b: IExtendedValueType ) => a === b;
  const isInRange = ( lower: IRangeType, upper: IRangeType, currentValue: IExtendedValueType ) => 
    lower !== undefined && upper !== undefined &&
    currentValue !== null && currentValue !== undefined &&  
    lower <= currentValue && upper >= currentValue;

  return getValidationInfo( variable, value, isEqual, isInRange, v => v as string );
}

export const getDateValidationInfo = ( variable: IConfigurationVariable, value: Moment | null ) => {
  const formatDate = ( v: IValueType ) => moment( v as string ).format( 'MM/DD/yyyy' );
  const isEqual = ( a: IExtendedValueType, b: IExtendedValueType ) => moment( a as IRangeType ).format() === moment( b as IRangeType ).format();
  const isInRange = ( lower: IRangeType, upper: IRangeType, currentValue: IExtendedValueType ) => 
    ( moment( lower ).isBefore( currentValue as Moment ) || isEqual( lower, currentValue ) ) && 
    ( moment( upper ).isAfter( currentValue as Moment ) || isEqual( upper, currentValue ) );

  return getValidationInfo( variable, value, isEqual, isInRange, formatDate );
}

const getValidationInfo = ( 
  variable: IConfigurationVariable, 
  value: IExtendedValueType,
  isEqual: ( a: IExtendedValueType, b: IExtendedValueType ) => boolean,  
  isInRange: ( lower: IRangeType, upper: IRangeType, currentValue: IExtendedValueType ) => boolean, 
  messageDelegate: ( v: IValueType ) => string ) => {
  //assume that unset value is always valid
  let valid = value === undefined || value === null;
  const validValues: string[] = [];
  if ( !variable || !variable.values ) {
    return { valid, validValues };
  }
  for ( const variableValue of variable.values ) {
    if ( Object.prototype.hasOwnProperty.call( variableValue, 'name' ) ) {
      if( isEqual( variableValue.value, value ) ) {
        valid = true;
      }
      validValues.push( `${messageDelegate( variableValue.value )}` );
    }

    if ( !Object.prototype.hasOwnProperty.call( variableValue, 'name' ) ) {
      if( isInRange( variableValue.lower, variableValue.upper, value ) ) {
        valid = true;
      }
      validValues.push( `[${messageDelegate( variableValue.lower )} - ${messageDelegate( variableValue.upper )}]` );
    }
  }

  return { valid, validValues };
}

export const getRangeDate = ( variable: IConfigurationVariable, propertyName: string ) => {
  if( !variable.values || !variable.values.length ) {
    return moment( new Date( 0, 1, 1 ) );
  }

  let value;

  if( propertyName === 'lower' ) {
    value = variable.values[0];
  } else if( propertyName === 'upper' ) {
    value = variable.values[variable.values.length - 1];
  } else {
    throw new Error( `unknown propertyName: ${propertyName}` )
  }

  if( value[propertyName] ) {
    return moment( value[propertyName] );
  }

  return moment( value.value as string );
}

export const fixTimezoneOffset = ( momentDate: Moment | null ) => {
  if( !momentDate ) {
    return null;
  }

  const copy = moment( momentDate );
  const date = momentDate.toDate();
  copy.add( date.getTimezoneOffset(), 'minutes' );
  return copy.toDate();
}

export const getDateOnly = ( momentDate: Moment | null ) => {
  if( !momentDate ) {
    return null;
  }

  if( !momentDate.isValid() ) {
    return momentDate;
  }

  const dateOnlyString = momentDate.format( 'yyyy-MM-DDT00:00:00[Z]' );
  return moment( dateOnlyString );
}

export const toInputFormat = ( isoDateString: string ) => {
  const datePart = isoDateString.split( 'T' )[0];
  const dateParts = datePart.split( '-' );
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
}
