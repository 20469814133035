import { Grid,Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Tooltip, Autocomplete,TextField,InputAdornment, Popper } from '@mui/material';
import React, { useState,useEffect, ReactElement } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { NotifyAPI } from '../api/NotifyAPI';
import { SessionStore } from '../services/SessionStore';
import { connect } from 'react-redux';
import { Combine, configurationDispatcher, configurationState, applicationSettingsDispatcher, applicationSettingsState, userSettingsDispatcher, userSettingsState, productSettingsState, productSettingsDispatcher, errorDispatcher, errorState, userPreferencesState } from '../store';
import { UrlHelper } from '../services/UrlHelper';
import { getLanguageCode , getViewId, GetToken, isConfigurationComplete, isSubModelAssigned } from '../services';
import { Trans, withTranslation } from 'react-i18next';
import { ViewHandler } from './ViewHandler';
import { LanguageSetting } from './LanguageSetting';
import { IApplicationSettings, IExtendedConfigureResponse, IUserSettings } from '../../types';
import {CurrencySetting} from './CurrencySetting';
import { EConfigurationType, EActionType, PopperModifier, ESessionStore, EUrlParams, ETabValue, EPageNames } from '../data/Constants';
import WarningIcon from '@mui/icons-material/Warning';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { AppAuthContext } from '../services/Contexts';
import { SaveAndClose } from './SaveAndClose';
import { MessagesPopover } from './MessagesPopover';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import DraggableComponent from './DraggableComponent';

const getDialogContent = ( loading: boolean,errorMessage: string | any[],dialog: string )=>{
  const content = errorMessage.length > 0 ? errorMessage : dialog;
  return loading ? <LinearProgress /> : content;
}

const displayCodeIcon = ( userSettings:IUserSettings )=>{
  if ( userSettings?.showCode ) {
    return <VisibilityIcon fontSize="medium" />
  } else {
    return <VisibilityOffIcon fontSize="medium" />
  }
}

const displayResetIcon = ( disableButton:boolean )=>{
  if ( disableButton ) {
    return <img className="zoom80" src="../../public/svg/eraser_disabled.svg"/>
  } else {
    return <img className="zoom80" src="../../public/svg/eraser.svg"/> 
  }
}


function disableActionButton( configuration:IExtendedConfigureResponse ) {
  return configuration.savedConfiguration?.state === EConfigurationType.Temp || configuration.savedConfiguration?.state === EConfigurationType.ReadOnly;
}

const disableResetIcon = ( configuration:IExtendedConfigureResponse,applicationSettings:IApplicationSettings ) => {
  return configuration.savedConfiguration?.state === EConfigurationType.Temp || configuration.savedConfiguration?.state === EConfigurationType.ReadOnly ? true : !applicationSettings.isConfigurationChanged;
}

function disableClearAllBtn( configuration:IExtendedConfigureResponse ) {
  return configuration.savedConfiguration?.state === EConfigurationType.Temp || configuration.savedConfiguration?.state === EConfigurationType.ReadOnly || configuration.access === EConfigurationType.ReadOnly
}

// Display product list in AutoComplete when length of input text is greater than 2
const searchProduct = ( e:any,onChangeProductSearchValue:( searchVal:string ) => void ) => {  
  if( e.target.value && ( e.target.value.length > 2 || e.target.value.length === 0 ) ) {
    onChangeProductSearchValue( e.target.value )
  }
}

const updateProductCatalog = ( newVal:any,props:any ) => {
  const { updateProductSearchValue,getProductCatalog,token,configuration,getMyConfigurations,setSearchValue,location,onChangeProductSearchValue,productSettings,searchValue } = props
  let searchKey = newVal === null ? '' : newVal;
  searchKey = typeof searchKey === 'string' ? searchKey : searchKey.title
  setSearchValue( searchKey );
  updateProductSearchValue( searchKey );
  onChangeProductSearchValue( searchKey )
  if( location.pathname === EPageNames.LandingPage && configuration.checkLandingTab.tabValue === ETabValue.Product && ( searchKey || searchValue ) ) {
    getProductCatalog( token,1,productSettings.productCatalogDetails.limit,searchKey,productSettings.productCatalogDetails.agCodes )
  }else if( location.pathname === EPageNames.LandingPage && configuration.checkLandingTab.tabValue === ETabValue.MyConfiguration && ( searchKey || searchValue ) ) {
    getMyConfigurations( token,1,productSettings.myConfigPageDetails.limit,searchKey,productSettings.myConfigPageDetails.channel )
  } 
}

const ActionDialog = ( { showDialog,onCloseDialog,header,loading,errorMessage,dialog,t,action,dialogType } :any )=>{
  return showDialog && 
    <Dialog open onClose={ onCloseDialog } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title" className="common-dialog-style error-dialog-style cursor-move">
      <DialogTitle className="header title">
        <Grid container alignItems="center">
          <><WarningIcon className="warningIcon"/> &nbsp;</> 
          <span > { header}</span>
        </Grid>
      </DialogTitle>
      <DialogContent className="action-panel-dialog-content">
        <DialogContentText>
          {getDialogContent( loading, errorMessage, dialog )}
        </DialogContentText>
      </DialogContent>
      {!loading && 
        <DialogActions>
          {dialogType !== EActionType.Success && dialogType !== EActionType.IncompleteConfiguration ? <>
            <Button
              className="text-capitalize CloseDialog"
              onClick={ onCloseDialog }
              data-testid="CloseDialog"
            >
              {t( 'button.cancel' )}
            </Button>
            {errorMessage.length === 0 && 
              <Button className="text-uppecase CallAction" onClick={ action } data-testid="CallAction">
                {t( 'button.ok' )}
              </Button>
            }
          </> : <Button className="text-uppecase CallAction" onClick={ onCloseDialog }>
            {t( 'button.ok' )}
          </Button>
          }
        </DialogActions>
      }
    </Dialog>
  
}

const getClassName = ( disableButton:boolean,type:string )=>{
  if( type === 'closeWithoutSave' ) {
    return 'closeWithoutSave-icon';
  }else if( type === 'notify' ) {
    return disableButton ? 'btn-disabled' : 'notify-icon';
  }
  return null;
 
}

const CloseWithoutSaveDialog = ( {disableButton,onCloseWithoutSavingDialog,t } :any )=>{
  return !IsDefaultFlowInSessionStore() ? <Tooltip title={ t( 'tooltip.closeWithoutSaving' ) }>
    <span>
      <IconButton
        className={ `action-panel-buttons ${getClassName( disableButton,'closeWithoutSave' )}` }
        onClick={ onCloseWithoutSavingDialog }
        data-testid="btn-close-configuration"
      >
        <DisabledByDefaultRoundedIcon />
      </IconButton>
    </span>
  </Tooltip> : <></>
}

const handleError = ( err:any,setError: ( error: object ) => void,setErrorMessage: ( message: string ) => void,onCloseDialog:() => void,t:( message:string ) => void,setLoaderMessage:( v:string|null )=>void )=>{
  if( err.response ) {
    setError( {code:err.response.status ? err.response.status : 500,
      message:err.response.data ? err.response.data.Message : err.message,
      page:'notify'} )
    setErrorMessage( err.response.data ? err.response.data.Message : err.message + '. ' + t( 'errorMessages.tryAgain' ) )
    onCloseDialog();
  }
  setLoaderMessage( null );
}

const CustomPopper = function ( props:any ) {
  return <Popper { ...props } placement="bottom" modifiers={ PopperModifier } />;
};

const onSearchValueChange = ( value,reason,onChangeProductSearchValue )=>{
  if( value === '' && reason === 'reset' ) {
    onChangeProductSearchValue( value )
  }
}

const ProductSearchBar = ( { location,configuration,updateProductSearchValue,getProductCatalog,token,getMyConfigurations,searchValue,setSearchValue ,onChangeProductSearchValue,searchMatches,productSettings,searchCount,t} :any )=>{
  if( location.pathname === EPageNames.LandingPage ) {
    return <Autocomplete
      id="product-search-box"
      clearOnBlur
      freeSolo
      options={ [] }
      className="search-bar"
      data-testid="search-bar"
      value={ searchValue }
      renderInput={ ( params ) => <TextField { ...params } className="search-inner-text" placeholder={ t( 'labels.search' ) } onChange={ ( e ) => searchProduct( e,updateProductSearchValue ) } InputProps={ { ...params.InputProps,
        startAdornment:  <InputAdornment position="end"> <SearchIcon />
        </InputAdornment>
      } }
      /> }
      onChange={ ( _, newVal ) => updateProductCatalog( newVal,{updateProductSearchValue,getProductCatalog,token,configuration,getMyConfigurations,setSearchValue,location,onChangeProductSearchValue,productSettings,searchValue} ) }
      PopperComponent ={ CustomPopper }
    />
  } else {    
    const options = searchMatches.map( ( option ) => {
      const familyName = option.familyName.toUpperCase();
      return {
        familyName: /[0-9]/.test( familyName ) ? '' : familyName,
        ...option,
      };
    } );
    return <>{searchValue.length > 0 ? <span className="result-found" > {t( 'labels.result' )}: {searchCount}</span> : null}<Autocomplete
      id="product-search-box"
      clearOnBlur = { !searchValue }
      onInputChange={ ( _e,value,reason ) => {
        onSearchValueChange( value,reason,onChangeProductSearchValue );
      } }
      freeSolo         
      options={ options.sort( ( a, b ) => -b.familyName.localeCompare( a.familyName ) ) }
      groupBy={ ( option ) => option.familyName }
      getOptionLabel={ ( option ) => option.title ? option.title : searchValue }      
      className="search-bar"
      value={ searchValue }
      renderInput={ ( params ) => <TextField { ...params } className="search-inner-text" placeholder={ t( 'labels.search' ) } onChange={ ( e ) => searchProduct( e,onChangeProductSearchValue ) } InputProps={ { ...params.InputProps,
        startAdornment:  <InputAdornment position="end"> <SearchIcon />
        </InputAdornment>
      } }
      /> }
      renderGroup={ ( params ) => 
        <div key={ params.group }>          
          <span className="display-options-header"> {params.group ? <><span>&nbsp;</span> {params.group}</> : ''}</span>
          {params.children}
        </div>
      }
      onChange={ ( _, newVal ) => updateProductCatalog( newVal,{updateProductSearchValue,getProductCatalog,token,configuration,getMyConfigurations,setSearchValue,location,onChangeProductSearchValue,productSettings} ) }
      PopperComponent ={ CustomPopper }
    /></>
   
  }
}

const closeTab = ( dialogType:string,onCloseDialog: ( ) => void,navigate:( path: string,replace:object ) => void,configurationId:string|null,auth ) =>{
  onCloseDialog();
  if( dialogType === EActionType.Notify ) {
    navigate( '/notified', {replace:true} );
  }else{
    navigate( '/closeTab', {replace:true} );
    window.parent.postMessage( {message:'configuration_close',cid:configurationId},'*' );
    window.parent.postMessage( {message:'configuration_close_parentOrigin',cid:configurationId},window.parent.location.origin );
  }
  setTimeout( ()=>{
    auth.signOut();
    SessionStore.clear()
  },2000 )
}

const handleNotifyResponse = ( res:any,
  setError: ( error: object ) => void,
  setLoading:( v:boolean )=>void,
  setShowDialog:( v:boolean )=>void,
  setApplicationSettings: ( applicationSettings: IApplicationSettings ) => void,
  handleSuccess: ( type: string ) => void,
  applicationSettings:IApplicationSettings )=>{

  if( res.status === 204 ) {
    setError( {code:204} )
    setLoading( false )
    setShowDialog( false )
    setApplicationSettings( { ...applicationSettings, isConfigurationChanged: false} );
  } else if( res.error ) {
    setLoading( false );
    setShowDialog( false );
  } else{
    handleSuccess( EActionType.Notify ); 
  }

}

function handleNotifySuccess( param ) {
  const {setDialogType, setDialog, t, setShowDialog, setNotified,dialogType,onCloseDialog,navigate,configurationId,auth} = param;
  if( IsDefaultFlowInSessionStore() ) {
    setDialogType( EActionType.Success ); 
    setDialog( t( 'successMessages.notifySuccess' ) );
    setShowDialog( true )
  }else{
    closeTab( dialogType,onCloseDialog,navigate,configurationId,auth );
    setShowDialog( false )
    setNotified( true );
  }
}

const tabChanged = ( configuration,setSearchValue,updateProductSearchValue,changeLandingTab )=>{
  if( configuration.checkLandingTab.isTabChanged ) {
    setSearchValue( '' );
    updateProductSearchValue( '' );
    changeLandingTab( false,configuration.checkLandingTab.tabValue );
  }
}

const closeDialog = ( reason,setDialog,setShowDialog )=>{
  if( reason !== 'backdropClick' ) {
    setDialog( '' );
    setShowDialog( false );
  }
}

const clearAllConfiguration = async ( props:any )=>{
  const {res,setError,setErrorMessage,onCloseDialog,t,setLoaderMessage,clearOptionalItem,handleSuccess} = props
  if( res.type === 'error-SET' ) { 
    handleError( res,setError,setErrorMessage,onCloseDialog,t,setLoaderMessage )     
  } else{
    clearOptionalItem()
    handleSuccess( EActionType.ClearAll );
  }
}
const resetActiveTab=( configuration, applicationSettings, setApplicationSettings )=>{//reset to previous active tab while reset cofiguration from submodel
  const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );
  const rootModel=configuration?.data?.product.id
  var activeTab = applicationSettings?.productsActiveTab[rootModel]?.tabIndex;
  if( modelPath ){
    activeTab = applicationSettings?.productsActiveTab[modelPath]?.tabIndex;
    const path = modelPath 
      ? [rootModel, ...modelPath.split( '.' )]
      : [rootModel];
    if( isSubModelAssigned( configuration, path.slice( 1 ) ) ){
      setApplicationSettings( { ...applicationSettings, activeTab: activeTab } );
    }
  }
  else{
    setApplicationSettings( { ...applicationSettings, activeTab: activeTab } );
  }
}

/**
 * Renders the action panel which includes functionality for 
 * saving, canceling and set preferences
 * @param {IComponentProps} props the properties for the action panel component
 * @returns {JSX.Element} the action panel component
 */

export const $ActionPanel = ( actionPanelProps : any ) => {
  const { t, loadConfiguration, setError, applicationSettings, setApplicationSettings, productSettings, userSettings, setUserSettings, clearConfiguration, clearOptionalItem, configuration, setLoaderMessage, notify, getProductCatalog, updateProductSearchValue, getMyConfigurations, changeLandingTab, onChangeProductSearchValue,userPreferences } = actionPanelProps;
  const [showDialog, setShowDialog] = useState( false );
  const [dialog, setDialog] = useState<string | ReactElement>( '' );
  const [searchValue, setSearchValue] = useState( '' );
  const [errorMessage, setErrorMessage] = useState( '' );
  const [header, setHeader] = useState( '' );
  const [notified,setNotified] = useState( false ); 
  const [loading,setLoading] = useState( false ); 
  const [dialogType, setDialogType] = useState( '' );
  const [searchMatches, setSearchMatches] = useState( [] );
  const [searchCount, setSearchCount] = useState( 0 );
  const location = useLocation();
  const languageCode = getLanguageCode();
  const navigate = useNavigate();
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId );
  
  const {token,auth} = GetToken();
  const loadProduct = async () => {
    await loadConfiguration( configurationId, languageCode, token,SessionStore.get( ESessionStore.ViewId ) )
    setApplicationSettings( { ...applicationSettings, isConfigurationChanged: false} );
    resetActiveTab(configuration,applicationSettings,setApplicationSettings)
  }

  useEffect( () => {    
    tabChanged( configuration,setSearchValue,updateProductSearchValue,changeLandingTab )
  }, [configuration.checkLandingTab.isTabChanged] )

  useEffect( () => {  
    setSearchMatches( applicationSettings.searchMatches )
    setSearchCount( applicationSettings.searchCount.searchCount )
  }, [JSON.stringify( applicationSettings.searchMatches ),applicationSettings.searchCount.searchCount] )

  useEffect( () => {  
    setUserSettings( {showCode: userPreferences?.showCode} )
  }, [userPreferences?.showCode] )
  
  const onNotifyDialog = () => {
    //Check if the configuration is complete to proceed with Save and Notify.
    const isCompleteConfiguration = isConfigurationComplete( configuration?.data?.sections );
    if( !isCompleteConfiguration ) { 
      setDialogType( EActionType.IncompleteConfiguration );
      setHeader( t( 'incompleteConfigurationDialog.incompleteConfiguration' ) );
      setDialog( <Trans i18nKey="incompleteConfigurationDialog.incompleteConfigurationMessage" components={ { 1: <span style={ {color:'red'} }/> } } /> ); 
    } else{
      setDialogType( EActionType.Notify );
      setHeader( t( 'closeWithSaveDialog.closeWithSaveChanges' ) );
      setDialog( t( 'closeWithSaveDialog.closeWithSaveConfirmationMessage' ) );
    }
    // To Reset Error Message while opening dialog
    setErrorMessage( '' );
    setShowDialog( true );
  }

  const onResetDialog = () => {
    setDialogType( EActionType.Reset );
    setErrorMessage( '' )
    setHeader( t( 'resetDialog.resetChanges' ) );
    setDialog( t( 'resetDialog.resetConfirmationMessage' ) );
    setShowDialog( true );
  }

  const onClearAllDialog = () => {
    setDialogType( EActionType.ClearAll );
    setErrorMessage( '' )
    setHeader( t( 'clearAllDialog.clearAllChanges' ) );
    setDialog( t( 'clearAllDialog.clearAllConfirmationMessage' ) );
    setShowDialog( true );
  }

  const onCloseWithoutSavingDialog = () =>{
    setDialogType( EActionType.CloseWithoutSaving );
    setErrorMessage( '' )
    setHeader( t( 'closeWithoutSavingDialog.closeWithoutSavingChanges' ) );
    setDialog( t( 'closeWithoutSavingDialog.closeWithoutSavingConfirmationMessage' ) );
    setShowDialog( true );
  }

  const onCloseDialog = ( _event?:any,reason?:any ) => {
    closeDialog( reason,setDialog,setShowDialog )
  }

  const handleSuccess = ( type:string )=>{
   
    switch( type ) {
      case EActionType.Reset:{
        setShowDialog( false );
        loadProduct();
        break;
      }
      case EActionType.Notify:{
        handleNotifySuccess( {setDialogType, setDialog, t, setShowDialog, setNotified,dialogType,onCloseDialog,navigate,configurationId,auth} )
        setApplicationSettings( { ...applicationSettings, isConfigurationChanged: false} );
        setLoading( false );
        setLoaderMessage( null );
        break;
      }
      case EActionType.ClearAll:{
        setShowDialog( false );
        setApplicationSettings( { ...applicationSettings, isConfigurationChanged: false} );
        setLoaderMessage( null );
        break;
      }
      default:break;
    }
  }

  const reset = () => {
    setErrorMessage( '' );
    setLoaderMessage( EActionType.Reset );
    NotifyAPI.reset( SessionStore.get( ESessionStore.ConfigurationId ) ,token ).then( ( res )=>{
      handleSuccess( EActionType.Reset );
      
    } ).catch( ( err )=>{
      handleError( err,setError,setErrorMessage,onCloseDialog,t,setLoaderMessage )
      
    } )
  }
  
  const clearAll = () => {
    const viewId:string = getViewId( );
    onCloseDialog();
    setErrorMessage( '' )
    setLoaderMessage( EActionType.ClearAll );
    clearConfiguration( SessionStore.get( ESessionStore.ConfigurationId ) ,token,viewId ).then( async ( res:any )=>{
      clearAllConfiguration( {res,setError,setErrorMessage,onCloseDialog,t,setLoaderMessage,loadConfiguration,configurationId, languageCode, token,setApplicationSettings,applicationSettings,clearOptionalItem,handleSuccess} )
    } ).catch( ( err:any )=>{
      handleError( err,setError,setErrorMessage,onCloseDialog,t,setLoaderMessage )
    } )
  }

  // Method to call the Notify API and handle error if Error Occurs
  const action = ()=>{
    if( dialogType === EActionType.Notify ) {
      notifyAPICall()
    } else if( dialogType === EActionType.ClearAll ) {
      clearAll()
    } else if ( dialogType === EActionType.CloseWithoutSaving ) {
      closeTab( dialogType,onCloseDialog,navigate,configurationId,auth )
    }else{
      reset()
    }
  }

  const notifyAPICall = ()=>{
    setShowDialog( false )
    setErrorMessage( '' );
    setLoaderMessage( EActionType.Notify );    
    notify( SessionStore.get( ESessionStore.ConfigurationId ),token ).then( ( res:any )=>{
      handleNotifyResponse( res,setError,setLoading,setShowDialog,setApplicationSettings,handleSuccess,applicationSettings );
      window.parent.postMessage( {message:'configuration_saved',cid:configurationId},'*' );  
      window.parent.postMessage( {message:'configuration_saved_parentOrigin',cid:configurationId},window.parent.location.origin );
    } ).catch( ( )=>{
      setLoading( false );
    } )
  }

  const onToggleCodes = () => {
    setUserSettings( {showCode: !userSettings.showCode} );
  };

  const disableButton = disableActionButton( configuration ) ;
  const disableResetButton = disableResetIcon( configuration,applicationSettings ) ;
  const disableClearAllButton = disableClearAllBtn( configuration );

  return (
    <AppAuthContext.Provider value={ token }>
      <Box className="rowFlex">
        <ActionDialog showDialog={ showDialog } onCloseDialog={ onCloseDialog } header={ header } loading={ loading } errorMessage={ errorMessage } dialog={ dialog } t={ t } action={ action } dialogType={ dialogType } />

        {token && <ProductSearchBar location={ location } configuration={ configuration } updateProductSearchValue={ updateProductSearchValue } getProductCatalog={ getProductCatalog } token={ token } getMyConfigurations={ getMyConfigurations } searchValue={ searchValue } setSearchValue={ setSearchValue } onChangeProductSearchValue={ onChangeProductSearchValue } searchMatches= { searchMatches } productSettings = { productSettings } searchCount ={ searchCount } t={ t }/>}

        <LanguageSetting />

        {!notified && token && configurationId &&
        <>
          <CurrencySetting />

          <MessagesPopover />

          <ViewHandler />

          <><Tooltip title={ t( 'userPreference.showCode' ) }>
            <span>
              <IconButton onClick={ onToggleCodes } disabled={ !configurationId } className = "code-icon action-panel-buttons" data-testid="btn-show-code">
                {displayCodeIcon( userSettings )}
              </IconButton>
            </span>
          </Tooltip></>
         
          <><Tooltip title={ t( 'tooltip.reset' ) }>
            <span className="resetIcon">
              <Button onClick={ onResetDialog }
                disabled={ disableResetButton }
                className="eraserIcon"
                data-testid="btn-reset-configuration"
              >{displayResetIcon ( disableResetButton )}</Button>
            </span>
          </Tooltip></>

          <><Tooltip title={ t( 'tooltip.clearAll' ) }>
            <span>
              <IconButton
                onClick={ onClearAllDialog }
                disabled={ disableClearAllButton }
                className="clearAllButton action-panel-buttons"
                data-testid="btn-clear-configuration"
              >
                <RestartAltIcon />
              </IconButton>
            </span>
          </Tooltip></>

          <SaveAndClose onNotifyDialog= { onNotifyDialog } disableButton= { disableButton } getClassName= { getClassName } />

          <CloseWithoutSaveDialog disableButton={ disableButton } onCloseWithoutSavingDialog={ onCloseWithoutSavingDialog }t={ t } />
          
        </>
        }
      </Box>
    </AppAuthContext.Provider>
  );
};

export const ActionPanel = withTranslation()( connect( 
  Combine( configurationState, applicationSettingsState, errorState, userSettingsState, productSettingsState, userPreferencesState ), 
  Combine( configurationDispatcher, applicationSettingsDispatcher, errorDispatcher, userSettingsDispatcher, productSettingsDispatcher )
)( $ActionPanel ) );
