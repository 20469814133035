import React from 'react';
import { UserPanel } from './UserPanel';
import { AuthContextProps, useAuth } from 'oidc-react';
import { Button } from '@mui/material';
import { authSettings } from '../api/OidcSettings';
import { useTranslation } from 'react-i18next';
import { configurationDispatcher } from '../store';
import { connect } from 'react-redux';
import { IHeaderLoginProps } from '../../types';
import { SessionStore } from '../services/SessionStore';
import { ESessionStore } from '../data/Constants';
import {Buffer} from 'buffer';
import { IsAppOpenedByExtApp } from '../services';
/**
 * Renders a button for login and logout functionality
 * @param {IHeaderLoginProps} props the props required by the component
 * @returns {JSX.Element} the user menu component
 */
export const $HeaderLogin = ( {setAuthState}:IHeaderLoginProps ) => {
  if ( authSettings.Developer_Mode ) {
    return <Button color="secondary">{'LOCAL DEVELOPER'}</Button>;
  }
  const {t} = useTranslation();
  let auth: AuthContextProps;
  let isLoggedin = false;
  let userName = '';
  if( IsAppOpenedByExtApp() ) {
    isLoggedin = true;
    const uname = Buffer.from( SessionStore.get( ESessionStore.Token ).split( '.' )[1], 'base64' );
    userName = JSON.parse( decodeURIComponent( uname.toString() ) ).name 
  }else{
    auth = useAuth();
    isLoggedin = !!( auth && auth.userData );
    userName = `${auth.userData?.profile.name}`;
  }
 
  return isLoggedin
    ? <UserPanel user={ userName } onLogout={ ()=>{
      setAuthState && setAuthState( 'logout' );
      auth.signOut();
    } }
    />
    : <Button color="secondary" onClick={ () => {
      setAuthState && setAuthState( 'login' );
      auth.signIn();
    } }
    >{t( 'button.login' )}</Button>;
};

export const HeaderLogin = connect( null,configurationDispatcher )( $HeaderLogin )