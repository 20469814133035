import { Dispatch } from 'react';
import { IAction } from '../../types';
import {AppStore} from '../store'

/**
 * @description Manages the service extensions
 */
export default class ServiceExtension {
  extensions: { [key: string]: {
    action: ( a: any, dispatchFn: Dispatch<IAction> ) => any;
    additionalDispatches?: string[] | IAction[];
    hideLoader:boolean;
  } };
  onBusy: ( dispatchFn: Dispatch<IAction>, busy: boolean ) => void;

  /**
   * @description Creates a new instance of the ServiceExtension class
   * @param {function} onBusy A function which will be triggered when action begins and ends
   */
  constructor( onBusy : ( dispatchFn: Dispatch<IAction>, busy: boolean ) => void ) {
    this.extensions = {};
    this.onBusy = onBusy;
  }

  /**
   * @description Adds a new action
   * @param {string} actionType An unique action identifier 
   * @param {boolean} hideLoader Flag true/false based on which loader icon will be closed 
   * @param {function} action A function which will be executed if the action was triggered
   * @param {object|object[]|string|string[]} additionalDispatches A string or object array which specifies the dispatches which will be executed afterwards
   * @returns {undefined}
   */
  add( actionType: string, action: ( a: any ) => Promise<any>,hideLoader:boolean , additionalDispatches?: string[] | IAction[] ) {
    if ( !action || !action.apply ) {
      throw new Error( 'ArgumentException: argument action is a required function argument' );
    }

    this.extensions[actionType] = {
      action,
      hideLoader,
      additionalDispatches
    };
  }

  /**
   * @description Calls the additionalDispatches
   * @param {object} store The store object of react-redux 
   * @param {object|object[]|string|string[]} dispatches A string or object array which specifies the dispatches which will be executed afterwards
   * @returns {undefined}
   */
  dispatchAfter( store: any, dispatches: any ) {
    if ( !dispatches ) {
      return;
    }

    if ( !Array.isArray( dispatches ) ) {
      store.dispatch( typeof dispatches === 'object' ? dispatches : { type: dispatches } );
      return;
    }

    for ( const dispatch of dispatches ) {
      store.dispatch( typeof dispatch === 'object' ? dispatch : { type: dispatch } );
    }
  }

  /**
   * @description Handles the next steps after each action
   * @param {Dispatch<IAction>} dispatchFn The dispatch function of react-redux
   * @param {object} actionResult The response of the action call
   * @returns {object} returns the result of the next step
   */
  handleNext( dispatchFn: Dispatch<IAction>, actionResult: any ) {
    return !actionResult || !actionResult.type ? actionResult : dispatchFn( actionResult );
  }

  /**
   * @description The middleware for react-redux. Checks the dispatched action and calls the corresponding service
   * @param {object} store The store object of react-redux
   * @param {Dispatch<IAction>} dispatchFn The dispatch function of react-redux
   * @param {object} action The dispatched action
   * @returns { object } Returns the Response
   */
  disableLoaderOfApiService( action,dispatchFn ) {
    if( action.type !== 'configurationAction-PRICE' ) {
      this.onBusy( dispatchFn, false );
    }
    if( action.type === 'configurationAction-PRICE' && action?.priceOnChange ) {            
      this.onBusy( dispatchFn, false );
    }
  }

  dataService( store: any, dispatchFn: Dispatch<IAction>, action: IAction ) {
    const extension = this.extensions[action.type];
    if( action?.apiType !== 'long' ) {
      extension && this.onBusy( dispatchFn, true );
    }
    dispatchFn( action );

    if ( !extension ) {
      return null;
    }

    const result = extension.action( action, dispatchFn );

    if ( !result || !result.then ) {
      console.warn( `Result is not a promise, please check action of: '${ action.type }'` );
      return null;
    }

    return result
      .then( ( actionResult: any ) => this.handleNext( dispatchFn, actionResult ) )
      .then( ( response: any ) => {
        response && !response.isError && this.dispatchAfter( store, extension.additionalDispatches );
        if( AppStore.getState().configuration.onLoadCheck === false ) {
          if( extension.hideLoader ) {
            this.onBusy( dispatchFn, false );
            
            AppStore.dispatch( {type:'configurationAction-ONLOADCHECK',key:true} )
          }
        } else {
          this.disableLoaderOfApiService( action,dispatchFn )          
        }
        return response;
      } );
  }
}